import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CubixsHttpService {
  private getResouceURL: string = "/cubix-api/SliceDice/getResource";
  private getLibraryListUrl: string = "cubix-api/SliceDice/CubixDesigner/getLibraryList";
  private pivotResultUrl: string = "/cubix-api/SliceDice/getPivotResult";
  private chartResultUrl: string = "/cubix-api/SliceDice/getChartResult";
  private listOfReportsUrl: string = "/cubix-api/SliceDice/getResouceList";

  private listofSchemasNewUrl: string = "/cubix-api/CubixDesigner/getSchemaList";
  private cancelJobUrl: string = "/cubix-api/SliceDice/cancelJob";
  private mapResultUrl: string = "/cubix-api/SliceDice/getMapResult";
  private dimensionPaletteURL: string = "/cubix-api/SliceDice/getDimensionPaletteJSON/";
  constructor(private http: HttpClient) { }
  serialize(obj: any) {
    let str = "";
    for (let key in obj) {
      if (str != "") {
        str += "&";
      }
      str += key + "=" + encodeURIComponent(obj[key]);
    }
    return str;
  }
}