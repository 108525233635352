import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { Router } from '@angular/router';
import { FULKRUM_WORKSPACE_URL, KEY_CLOAK_REALM_NAME, REDIRECT_URI, KEY_CLOAK_CLIENT_ID } from '../app.constant';
import { CiamLoginComponent } from '../ciamlogin/ciamlogin.component';
import { PagesService } from '../pages.service';



@Injectable({ providedIn: 'root' })
export class PrimaryNavService {
  shortName: string = "OA";
  constructor(private pagesService: PagesService, private http: HttpClient, private keycloakService: KeycloakService, private router: Router) { }



  userRoles: string[];
  showDashboard: boolean = false;
  showDI: boolean = false;
  showCubix: boolean = false;
  showAdmin: boolean = false;
  haveAnyRole: boolean = false;
  TENANT_ID = localStorage.getItem("tenant");
  roleMapping = new Map();
  getData(): Map<string, boolean> {
    this.userRoles = this.keycloakService.getUserRoles();
    this.userRoles.forEach(role => {
      if (role == 'ROLE_VIEWER') {
        this.showDashboard = true;
      } else if (role == 'ROLE_DATA_MANAGER') {
        this.showDI = true;
      } else if (role == 'ROLE_CUBIX_USER') {
        this.showCubix = true;
      } else if (role == 'ROLE_ADMIN') {
        this.showCubix = true;
        this.showDI = true;
        this.showDashboard = true;
        this.showAdmin = true;
      }
    });
    if (this.showDashboard || this.showDI || this.showCubix || this.showAdmin) {
      this.haveAnyRole = true;
    }


    this.roleMapping.set("haveanyrole", this.haveAnyRole);
    this.roleMapping.set("showCubix", this.showCubix);
    this.roleMapping.set("showDI", this.showDI);
    this.roleMapping.set("showDashboard", this.showDashboard);
    this.roleMapping.set("showAdmin", this.showAdmin);


    return this.roleMapping;
  }

  captureLog(details): Observable<any> {
    return this.http.post('http://mumchceo10:9954/api/Findash/logging', details);
  }

  getAllSolutionsLandingPage(userName: string): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(FULKRUM_WORKSPACE_URL + `api/landing/getsolutions/%7Bislock%7D?pageNumber=0&pageSize=0&startsWith=&islock=true`, { headers: headersList });

  }

  getUserSessions(): any {
    const headers = new HttpHeaders()
    return this.http.get(this.pagesService.redirect + 'ciamservice/ciam/v1/user/multipleSessionsExist/' + CiamLoginComponent.realm, { 'headers': headers })
  }

  getkillSessions(): any {
    const headers = new HttpHeaders()
    return this.http.get(this.pagesService.redirect + 'ciamservice/ciam/v1/user/killSessions/' + CiamLoginComponent.realm, { 'headers': headers })
  }

  validateSessions(): any {
    const headers = new HttpHeaders()
    return this.http.get(this.pagesService.redirect + 'ciamservice/ciam/v1/user/validateSessions/sessionId/' + this.keycloakService.getKeycloakInstance().sessionId + '/realm/' + CiamLoginComponent.realm, { 'headers': headers })
  }

  getTenantListByUsername(formValues: any): Observable<any> {
    const headers = new HttpHeaders();
    return this.http.get(this.pagesService.redirect + `ciamservice/ciam/v1/user/get-tenantIds-list-by-username/${formValues}`,
      { 'headers': headers }
    );
  }

  getUserDetailsByUserName(data: any, tenantId: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(this.pagesService.redirect + `ciamservice/ciam/v1/user/get-user-details/tenantid/${tenantId}/username/${data}`, {
      headers: headersList,
    });
  }
  getCiamApplicationDetails(tenantId: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(this.pagesService.redirect + `ciamservice/ciam/v1/application/get-ciam-application-details/tenantId/${tenantId}`, {
      headers: headersList,
    });
  }
  getLastLoggedByUserId(data: any, tenantId: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(this.pagesService.redirect + `ciamservice/ciam/v1/user/getuserlastlogin/tenantId/${tenantId}/userId/${data}/clientId/${KEY_CLOAK_CLIENT_ID}`, {
      headers: headersList,
    }
    );
  }
  getUserDetails(formValues: any): Observable<any> {
    return this.http.post(this.pagesService.redirect + `ciamservice/ciam/v1/user-service/get-user-details`, formValues);
  }
}