<div style="background-color: #F6F8FA;margin-bottom: 20px;">
    <div class="layout-content">
        <form [formGroup]="MarketplaceForm" autocomplete="off" novalidate>
            <div class="">
                <div id="columns_1_id" name="columns_1_name" class="p-grid   ">
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined undefined ">
                        <img alt="" src="{{this.redirect}}navbar/marketplace1.svg"
                            style="width: 226px;position: absolute;height: 187px;margin-left: 81%;margin-top: 1%;" />
                        <div class="">
                            <div id="columns_1_0_columns_1_id" name="columns_1_0_columns_1_name"
                                class="p-grid p-jc-center  ">
                                <div
                                    class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 p-d-flex p-jc-center p-mt-2">
                                    <div class="">
                                        <span class="d-block">
                                        </span>
                                        <h6 id="columns_1_0_columns_1_0_ccparagraph_1"
                                            name="columns_1_0_columns_1_0_ccparagraph_1_name">
                                            Welcome to CRISIL’S Marketplace!, Discover &amp; deploy the cloud software
                                            you want.
                                        </h6>
                                    </div>
                                </div>
                                <div
                                    class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 p-d-flex p-jc-center p-pt-0">
                                    <div class="">
                                        <span class="d-block"><label id="columns_1_0_columns_1_1_ccparagraph_1"
                                                [hidden]="true">
                                                Paragraph</label>
                                        </span>
                                        <p id="columns_1_0_columns_1_1_ccparagraph_1"
                                            name="columns_1_0_columns_1_1_ccparagraph_1_name">
                                            Marketplace lets you quickly deploy software on Cloud
                                        </p>
                                    </div>
                                </div>
                                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 p-d-flex p-jc-center">
                                    <div class="p-fluid" style="width: 420px;height: 40px;">
                                        <span class="p-input-icon-left"><i aria-hidden="true" class="pi pi-search"></i>
                                            <input type="text" id="columns_1_0_columns_1_2_ccprimeinputtext_1_id"
                                                name="columns_1_0_columns_1_2_ccprimeinputtext_1_name"
                                                formControlName="columns_1_0_columns_1_2_ccprimeinputtext_1" pInputText
                                                placeholder="Search"
                                                (input)="serviceTableid.filterGlobal($event.target.value, 'contains')">
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined p-pt-3">
                        <div class="p-formgrid p-grid">
                            <div class="p-col-12 p-sm-10 p-md-10 p-lg-10 p-xl-10 p-offset-undefined left">
                                <h6 id="columns_1_1_ccprimeheading_1_id" name="columns_1_1_ccprimeheading_1_name"
                                    style="font-size: 16px;">
                                    Featured Apps
                                </h6>
                            </div>
                        </div>
                        <div id="cardHeightApp" style="margin-top: -5px;">
                            <p-table [rowHover]="true" id="serviceTable_id" #serviceTableid name="serviceTable"
                                styleClass="p-datatable-gridlines" [value]="tableList"
                                class="card-mode card-grid-table desktop tableBorder"
                                [globalFilterFields]="['applicationName','description']" style="display: block;">
                                <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                                    <div class="p-col-12 p-xl-3 p-lg-3 p-md-3 p-sm-3"
                                        style="display: inline-grid;max-height: 175px;min-height: 175px;">
                                        <tr>
                                            <p-card (click)="routeToDetail(rowData)">
                                                <ng-template pTemplate="body" style="height: 180px;">
                                                    <div class="avatar-header">
                                                        <p-avatarGroup>
                                                            <p-avatar shape="circle" [ngClass]='getColor(index)'>
                                                                {{rowData.applicationName?.substring(0,1) | titlecase}}
                                                            </p-avatar>
                                                            <span class="avatar-name p-pl-2" style="font-size: 14px;">
                                                                <span pTooltip="{{rowData.applicationName}}"
                                                                    tooltipPosition="bottom"
                                                                    *ngIf="rowData.applicationName?.length >= 20">
                                                                    {{rowData.applicationName?.substring(0,20) +
                                                                    "..." }}
                                                                </span>
                                                                <span *ngIf="rowData.applicationName?.length < 20">
                                                                    {{rowData.applicationName}}
                                                                </span>
                                                            </span>
                                                        </p-avatarGroup>
                                                        <div class="p-pt-3" style="font-size:13px;">
                                                            <span pTooltip="{{rowData.description}}"
                                                                tooltipPosition="bottom"
                                                                *ngIf="rowData.description?.length >= 70">
                                                                {{rowData.description?.substring(0,70) +
                                                                "..." }}
                                                            </span>
                                                            <span *ngIf="rowData.description?.length < 70">
                                                                {{rowData.description}}
                                                            </span>
                                                            <span
                                                                *ngIf="rowData.description == null || rowData.description == ''">
                                                                -
                                                            </span>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </p-card>
                                        </tr>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="7">No Records Found.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined">
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>