import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PagesService } from '../pages.service';

@Component({
  selector: 'navbar-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {

  constructor(private router: Router, private pagesService: PagesService) {
  }

  ngOnInit(): void {
    console.log("location " + location.href.slice(-5));
    if (location.href.slice(-5) != "/ciam" && !this.pagesService.alreadyLogin) {
      console.log("inside if 17");
      this.router.navigate(["/"]);
    }
  }

}
