import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { KEY_CLOAK_CLIENT_ID, KEYCLOAK_API_URL, KEY_CLOAK_REALM_NAME } from '../app.constant';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { PagesService } from '../pages.service';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { CiamLoginComponent } from '../ciamlogin/ciamlogin.component';
@Component({
  selector: 'jhi-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  providers: [MessageService],
})
export class RegistrationComponent implements OnInit {
  @ViewChild('ngOtpInput') ngOtpInputRef: any;
  hasCode = false;
  public settings = {
    length: 6,
    numbersOnly: true
  };

  RegistrationForm = this.fb.group({
    firstName: [null, [Validators.required]],
    lastName: [null, [Validators.required]],
    accountType: [null, [Validators.required]],
    organizationName: [null, [Validators.required]],
    domainName: [null, [Validators.required]],
    data: [null, []],
    domainName1: [null, [Validators.required]],
  });
  verifyEmailForm = this.fb.group({
    emailId: [null, [Validators.required]],
  }
  );
  verifyOTPForm = this.fb.group({

  }
  );
  defaultValue: boolean = false;
  pageloadvalues: any;
  columns_1_0_columns_1_0_carousel_1_1List: any[] =
    [{
      "abc": 'abc',
    }]
    ;
  clipPropertypersonalDetails: string = '';
  clipPropertyaccountDetails: string = '';
  accounttype: any;
  accountTypeList: { label: string; value: string; }[];
  accountTypeselectedTypes: string;
  switchScreen: boolean = false;
  switchScreen1: boolean;
  product: { image: string; description: string; detail: string; }[];
  data: any;
  display: string;
  btnFlag: boolean = false;
  resendFlag: boolean = false;
  otp: any;
  id: any;
  organizationName: any;
  domainEditFlag: boolean = true;
  domain: any;
  routeData: any;
  appId: any;
  applicationName: any;
  planId: any;
  messageFlag: boolean = false;
  email: any;
  maskemail: any;
  maskedEmail: string;
  redirect: any;
  messageFlag1: boolean = false;
  editedFlag: boolean = false;
  imagePath: string;
  multiTenancyFlag: string;
  realm: string;

  constructor(private router: Router, protected activatedRoute: ActivatedRoute, private fb: UntypedFormBuilder, private keycloak: KeycloakService, private pagesService: PagesService, private messageService: MessageService) {
    const navigation = this.router.getCurrentNavigation();
    this.routeData = navigation?.extras?.state;
    console.log(navigation, 'naviagtion');
    if (Object.keys(navigation.extractedUrl.queryParams).length != 0) {
      console.log("in if");
      this.planId = navigation.extractedUrl.queryParams.planId;
      this.applicationName = navigation.extractedUrl.queryParams.appName;
      this.appId = navigation.extractedUrl.queryParams.appId;
    }
    else {
      console.log("in else");
      this.appId = this.routeData?.appId;
      this.applicationName = this.routeData?.appName;
      this.planId = this.routeData?.planId;
    }
  }
  ngOnInit(): void {
    this.multiTenancyFlag = environment.MULTI_TENANCY_ENABLE + ""
    this.imagePath = environment.IMAGE_PATH;
    this.redirect = this.pagesService.redirect;
    if (this.pagesService.alreadyLogin) {
      this.router.navigate(["/ciam"]);
    }
    else {
      this.router.navigate(["/register"]);
    }
    this.onClipConvertingpersonalDetails()
    this.onClipConvertingaccountDetails()
    this.accountTypeList = [{ 'label': "Business", 'value': 'Business' }, { 'label': "Personal", 'value': 'Personal' },];
    this.accountTypeselectedTypes = "Business";
    this.product = [
      { image: this.redirect + 'navbar/ciamSlider1.svg', description: 'Easy and quick configurations', detail: 'Reduce repeated work and focus more on heavy configs' },
      { image: this.redirect + 'navbar/ciamSlider2.svg', description: 'One place for all CRISIL applications', detail: 'Access all applications and services from centralized place' },
      { image: this.redirect + 'navbar/ciamSlider3.svg', description: 'Application designing & development', detail: 'Reduce amount of time required to develop applications' },
      { image: this.redirect + 'navbar/ciamSlider4.svg', description: 'Low-code No-code Platform', detail: 'Simplify your development by just drag and drop' }
    ];
  }

  onClipConvertingpersonalDetails() {
    let title = 'Personal Details'
    this.clipPropertypersonalDetails = title.substring(0, +1) + '...';
  }

  onClipConvertingaccountDetails() {
    let title = 'Account details'
    this.clipPropertyaccountDetails = title.substring(0, +1) + '...';
  }

  removepselectchipaccountTypeChip(item: any) {
    this.accountTypeList.splice(this.accountTypeList.indexOf(item), 1);
  }
  tickpselectchipaccountTypeChip(item: any) {
    this.accountTypeList.slice(this.accountTypeList.indexOf(item), 1);
  }
  yourMethod() {
    this.ngOtpInputRef.setValue("");
  }
  public onInputChange(e: any) {
    if (e?.length == this.settings?.length) {
      this.otp = e;
      this.btnFlag = true;
    } else {
      this.btnFlag = false;
    }
  }
  verifyEmail(): void {
    this.timer(2);
    this.switchScreen = true;
    const reqBody = {};
    this.email = this.verifyEmailForm.value.emailId;
    let arr = this.email.split('@');
    let starts = arr[0].substring(0, 2);
    let end = arr[0].substring(arr[0].length - 1);
    this.maskedEmail = starts + ('*'.repeat(arr[0].length - 3)) + end + '@' + arr[1];
    reqBody['emailId'] = this.verifyEmailForm.value.emailId;
    const formValues = reqBody;
    this.pagesService.verifyEmail(formValues).subscribe(
      results => {
        this.id = results.success.result.id;



      }

    );
  }
  verifyOTP() {
    const reqBody = {};
    reqBody['otp'] = this.otp;
    reqBody['emailId'] = this.verifyEmailForm.value.emailId;
    reqBody['id'] = this.id;
    const formValues = reqBody;
    this.messageFlag = true;
    this.pagesService.verifyOTP(formValues).subscribe(

      results => {
        this.messageFlag = true;

        if (results.success.message == 'Invalid OTP') {
          this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Invalid OTP.' });
          setTimeout(() => {
            this.messageFlag = false;
          }, 3000);
        } else {
          this.switchScreen1 = true;
        }
      }
    );
  }


  timer(minute) {
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = minute < 10 ? "0" : "";

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        this.resendFlag = true;
        clearInterval(timer);
      }
    }, 1000);
  }
  loginRoute() {
    const reqBody = {};

    reqBody['first_name'] = this.RegistrationForm.value.firstName;
    reqBody['last_name'] = this.RegistrationForm.value.lastName;
    reqBody['acc_type'] = this.accountTypeselectedTypes;
    reqBody['org_name'] = this.RegistrationForm.value.organizationName;
    reqBody['domain_name'] = "crisil.com";
    reqBody['email_id'] = this.verifyEmailForm.value.emailId;
    reqBody['alias_name'] = this.RegistrationForm.value.domainName;
    reqBody['aaplication_id'] = [this.appId];
    reqBody['plan_id'] = [this.planId];
    const formValues = reqBody;
    this.messageFlag1 = true;
    this.pagesService.register(formValues).subscribe(
      results => {
        this.messageFlag1 = true;
        this.messageFlag1 = true;
        this.messageService.add({ key: 'myKey2', severity: 'success', detail: 'Username  & one time password is sent to your registered Email ID.' });
        setTimeout(() => {
          this.messageFlag1 = false;
          setTimeout(() => {
            this.router.navigate(["/marketplace"]);
          }, 2000);
        }, 3000);


      },
      error => {
        this.messageFlag1 = true;
        this.messageService.add({ key: 'myKey2', severity: 'error', detail: 'Failed to create account.' });
        setTimeout(() => {
          this.messageFlag1 = false;
        }, 3000);
      }
    );


  }
  //}
  domainname() {
    if (!this.editedFlag) {
      this.organizationName = this.RegistrationForm.value.organizationName;
      this.RegistrationForm.value.domainName = this.organizationName;
      this.domain = '-' + this.applicationName + '.crisil.com';
      this.RegistrationForm.value.domainName1 = this.domain;
    } else {
      this.domain = '-' + this.applicationName + '.crisil.com';
      this.RegistrationForm.value.domainName1 = this.domain;
    }

  }
  acconttype() {
    console.log("");
  }
  alreadayHaveAc() {
    if (this.multiTenancyFlag == "true") {
      CiamLoginComponent.realm = location?.hostname.slice(0, location?.hostname.indexOf('.'));
    }
    else {
      CiamLoginComponent.realm = KEY_CLOAK_REALM_NAME;
    }
    console.log("realm " + CiamLoginComponent.realm);
    this.keycloak.init({
      config: {
        url: KEYCLOAK_API_URL,
        realm: CiamLoginComponent.realm,
        clientId: KEY_CLOAK_CLIENT_ID
      },
      loadUserProfileAtStartUp: false,
      initOptions: {
        onLoad: 'login-required',
        checkLoginIframe: false,
      },
      enableBearerInterceptor: true,

      bearerExcludedUrls: [],
      bearerPrefix: 'Bearer'
    });
    this.keycloak.keycloakEvents$.subscribe({
      next: (e) => {
        if (e.type == KeycloakEventType.OnTokenExpired) {
          this.keycloak.updateToken(30);
        }
      }
    });
  }
  routerLinkFunc(url: any) {
    this.router.navigate([url]);
  }
}