import { PagesService } from './../pages.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Observable } from 'windowed-observable';
import { ACTIVE_PROJECT, FULKRUM_WORKSPACE_URL } from '../../app/app.constant';


@Component({
  selector: 'jhi-footer',
  templateUrl: './footer.component.html',
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent implements OnInit {


  items = [];
  imageSrc: any;
  activeProjectIsCiam: boolean = false;
  activeProjectIsWorkspace: boolean = false;
  redirect: any;
  active_project: any;


  constructor(private pagesService: PagesService, private router: Router) {
    sessionStorage.setItem('bottomTabs', null);
  }

  ngOnInit(): void {
    this.redirect = this.pagesService.redirect;
    this.active_project = ACTIVE_PROJECT;


    this.imageSrc = FULKRUM_WORKSPACE_URL + 'microui/ProjectLogo/fulkrum/Fulkrum-Logo.png';

    const observable = new Observable('tabsObservable');
    observable.subscribe(status => {
      if (status[status.length - 1].newTab) {
        let str = String(status[status.length - 1].routerLink);
        sessionStorage.setItem("previousUrl", str);
        const url = this.router.serializeUrl(
          this.router.createUrlTree([''])
        );
        window.open(url, '_blank');
        setTimeout(() => {
          sessionStorage.removeItem("previousUrl");
        }, 1500)
      } else {
        this.items = status;
        this.router.navigate([this.items[this.items.length - 1].routerLink]);
      }
    });

  }

  activeIndex: any;
  onFooterTabRoute(): void {
    if (this.activeIndex == undefined) {
      this.activeIndex = 0;
    }
    const data = this.items[(this.activeIndex)];
    sessionStorage.setItem("previousUrl", '/workspace/pages/fulkrumlandingpage');
    this.router.navigate([data.routerLink]);
  }

  handleClose(data: any): void {

    let tempItemList = null;

    this.items = JSON.parse(sessionStorage.getItem('bottomTabs'));
    tempItemList = this.items[data.index];

    this.items.splice(data.index, 1);

    this.items = [...this.items];
    sessionStorage.setItem('bottomTabs', JSON.stringify(this.items));

    let temVarUrl;

    let temVarList = tempItemList.routerLink.split("/")[2] + '_' + tempItemList.routerLink.split("/")[3];
    if (tempItemList.routerLink.split("/")[1] === 'designer-app') {
      temVarUrl = this.router.url.split("/")[3] + '_' + this.router.url.split("/")[4];
    } else if (tempItemList.routerLink.split("/")[1] === 'cubixui') {
      temVarList = tempItemList.routerLink.split("/")[1] + '_' + tempItemList.routerLink.split("/")[3];
      temVarUrl = this.router.url.split("/")[1] + '_' + this.router.url.split("/")[3];
    } else {
      temVarUrl = this.router.url.split("/")[2] + '_' + this.router.url.split("/")[3];
    }

    if (temVarList.toLowerCase() === temVarUrl.toLowerCase()) {
      tempItemList = this.items[(data.index) - 1];
      this.router.navigate([tempItemList.routerLink]);
    }
  }
}
