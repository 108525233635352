<div style="background-color: #F6F8FA;margin-bottom: 35px;">
    <div class="layout-content">
        <form [formGroup]="ApplicationDetailForm" autocomplete="off" novalidate>
            <div class="">
                <div id="columns_1_id" name="columns_1_name" class="p-grid   ">
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined undefined p-p-0">
                        <div>
                            <p-breadcrumb id="breadcrumbTop_id" name="breadcrumbTop_name" [model]="breadcrumbTopList"
                                [home]="homeiconbreadcrumbTop">
                            </p-breadcrumb>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined"
                        style="padding-left: 15px;padding-right: 15px;">
                        <div class="">
                            <p-tabView id="tabView_id" [(activeIndex)]="ApplicationDetailForm.value.tabView"
                                (onChange)="handleChangetabView($event)">
                                <p-tabPanel [selected]="true" header="Overview">
                                    <div class="">
                                        <div id="tabView_0_columns_1_id" name="tabView_0_columns_1_name"
                                            class="p-grid   ">
                                            <div
                                                class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined undefined p-p-0">

                                                <div class="">
                                                    <div class="d-flex flex-wrap"
                                                        style=" padding:4px 0px; height:'auto'">
                                                        <p-card id="tabView_0_columns_1_0_card_1_id"
                                                            name="tabView_0_columns_1_0_card_1_name"
                                                            styleClass="           ">
                                                            <div class="p-grid">
                                                                <div class="p-col p-pr-4">
                                                                    <div class="p-formgrid p-grid">
                                                                        <div
                                                                            class="p-col-12 p-sm-3 p-md-3 p-lg-3 p-xl-3 p-offset-undefined left p-pl-0">
                                                                            <img alt=""
                                                                                *ngIf="this.showDefaultLogo==true"
                                                                                src="{{this.redirect}}navbar/Myronlogo1.svg"
                                                                                style="height: 48px;width:107px;">
                                                                            <img alt="" src="{{BASE_URL + imageURL}}"
                                                                                *ngIf="this.showDefaultLogo==false"
                                                                                width="200px" />
                                                                        </div>
                                                                        <div
                                                                            class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                                                                        </div>
                                                                        <div
                                                                            class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined p-pl-0 p-pt-3">
                                                                            <div class="">
                                                                                <p id="tabView_0_columns_1_0_card_1_columns_1_0_columns_1_2_ccparagraph_1"
                                                                                    name="tabView_0_columns_1_0_card_1_columns_1_0_columns_1_2_ccparagraph_1_name"
                                                                                    style="font-size: 14px;text-align: justify;">
                                                                                    {{this?.description}}
                                                                                </p>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="p-col-fixed">
                                                                    <img alt=""
                                                                        src="{{this.redirect}}navbar/appdetail.svg"
                                                                        style="width: 358px;height: 221px;"
                                                                        *ngIf="this.showDefaultVideo==true" />
                                                                    <video width="450px" controls
                                                                        *ngIf="this.showDefaultVideo==false">
                                                                        <source src="{{BASE_URL + videoURL}}"
                                                                            type="video/mp4">Your browser does not
                                                                        support the video tag.
                                                                        <track label="English" kind="captions"
                                                                            srclang="en" src="" default>
                                                                    </video>
                                                                </div>
                                                            </div>
                                                        </p-card>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined p-pt-4 p-pl-0 p-pr-0"
                                                *ngIf="this.myron=='myron'">
                                                <div class="p-formgrid p-grid">
                                                    <div
                                                        class="p-col-12 p-sm-10 p-md-10 p-lg-10 p-xl-10 p-offset-undefined left p-pl-0">
                                                        <h6 id="columns_1_1_ccprimeheading_1_id"
                                                            name="columns_1_1_ccprimeheading_1_name"
                                                            style="font-size: 16px;">
                                                            Product Features
                                                        </h6>
                                                    </div>
                                                </div>
                                                <div id="cardHeightApp"
                                                    style="margin-top: -25px;margin-left: -10px;margin-right: -7px;">
                                                    <p-table [rowHover]="true" id="serviceTable_id" #serviceTableid
                                                        name="serviceTable" styleClass="p-datatable-gridlines"
                                                        [value]="productfeatures"
                                                        class="card-mode card-grid-table desktop tableBorderApp"
                                                        [globalFilterFields]="['applicationName','description']"
                                                        style="display: block;">
                                                        <ng-template pTemplate="caption">
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                                                            <div class="p-col-12 p-xl-3 p-lg-3 p-md-3 p-sm-3"
                                                                style="display: inline-grid;max-height: 175px;min-height: 175px;">
                                                                <tr>
                                                                    <p-card>
                                                                        <ng-template pTemplate="body"
                                                                            style="height: 180px;">
                                                                            <div class="avatar-header">
                                                                                <p-avatarGroup>
                                                                                    <p-avatar shape="circle"
                                                                                        [ngClass]='getColor(index)'>
                                                                                        {{rowData.featureName?.substring(0,1)
                                                                                        | titlecase}}
                                                                                    </p-avatar>
                                                                                    <span class="avatar-name p-pl-2"
                                                                                        style="font-size: 14px;">

                                                                                        <div>
                                                                                            <ng-container>
                                                                                                {{rowData.featureName}}
                                                                                            </ng-container>
                                                                                        </div>
                                                                                    </span>
                                                                                </p-avatarGroup>
                                                                                <div class="p-pt-3"
                                                                                    pTooltip="{{rowData?.description?.length >= 80 ? rowData?.description : ''}}"
                                                                                    tooltipPosition="bottom"
                                                                                    style="font-size:13px;">
                                                                                    <ng-container
                                                                                        *ngIf="rowData?.description?.length > 80; else tooltip">
                                                                                        {{rowData?.description?.substring(0,80)
                                                                                        + "..." }}
                                                                                    </ng-container>
                                                                                    <ng-template #tooltip>
                                                                                        {{rowData?.description}}
                                                                                    </ng-template>
                                                                                </div>
                                                                            </div>
                                                                        </ng-template>
                                                                    </p-card>
                                                                </tr>
                                                            </div>
                                                        </ng-template>
                                                        <ng-template pTemplate="emptymessage">
                                                            <tr>
                                                                <td colspan="7">No Records Found.</td>
                                                            </tr>
                                                        </ng-template>
                                                    </p-table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p-tabPanel>
                                <p-tabPanel header="Plans and Pricing">
                                    <p-card id="tabView_1_columns_1_1_columns_1_0_card_1_id"
                                        name="tabView_1_columns_1_1_columns_1_0_card_1_name" styleClass="           ">
                                        <div class="p-formgrid p-grid">
                                            <div class="p-col-12 p-sm-2 p-md-2 p-lg-2 p-xl-2 p-offset-undefined">

                                            </div>
                                            <div
                                                class="p-col-12 p-sm-8 p-md-8 p-lg-8 p-xl-8 p-offset-undefined p-text-center">
                                                <span style="font-weight: 600;font-size: 14px;">We keep our options
                                                    simple! </span><br>
                                                <span style="font-size: 12px;">It makes things easier for everyone,for
                                                    more info <a>Contact us</a>.</span>
                                            </div>
                                            <div class="p-col-12 p-sm-2 p-md-2 p-lg-2 p-xl-2 p-offset-undefined">
                                            </div>
                                            <div class="p-col-12 p-sm-4 p-md-4 p-lg-4 p-xl-4 p-offset-undefined">
                                                <span *ngIf="this.planCount!=0">
                                                    Total plans: <p-badge [value]="this.planCount"
                                                        styleClass="mr-2"></p-badge>
                                                </span>

                                            </div>
                                            <div class="p-col p-offset-undefined">

                                            </div>
                                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined"
                                                style="margin-top: 15px;">
                                                <p-table [rowHover]="true" id="serviceTable_id" #serviceTableid1
                                                    name="serviceTable" styleClass="p-datatable-gridlines pricing"
                                                    class="card-grid-table desktop card-mode" [rows]="3"
                                                    [value]="planList" style="display: none;">
                                                    <ng-template pTemplate="caption">
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                                                        <div class="p-m-3"
                                                            style="display: flex;height: 510px;width: 260px;">
                                                            <tr style="    background: transparent;border: none;">
                                                                <p-card class="pricing">
                                                                    <ng-template pTemplate="header">
                                                                        <div class="p-d-block">
                                                                            <div class="p-text-center"
                                                                                style="height: 300px;">
                                                                                <div class="p-p-1">
                                                                                    <span
                                                                                        style="font-size: 14px;font-weight: 500;">{{rowData.planName}}</span>
                                                                                </div>
                                                                                <div class="p-p-1">
                                                                                    <span
                                                                                        style="font-size:11px ;">{{rowData.planDescription}}</span>
                                                                                </div>
                                                                                <div class="p-p-1">
                                                                                    <span
                                                                                        class="plan-price">{{rowData.price}}</span>
                                                                                </div>
                                                                                <div class="custom-card p-p-1 p-mt-2"
                                                                                    style="width: 236px;font-size: 12px;height: 375px;">
                                                                                    <div class="p-p-1 p-text-left"
                                                                                        style="display: flex;">
                                                                                        <img alt=""
                                                                                            src="{{this.redirect}}navbar/check.svg"
                                                                                            style="width: 17px;margin-right: 8px;" />
                                                                                        <span>No of Users:
                                                                                            {{rowData.attributes.noOfUsers}}</span>
                                                                                    </div>
                                                                                    <div class="p-p-1 p-text-left"
                                                                                        style="display: flex;">
                                                                                        <img alt=""
                                                                                            src="{{this.redirect}}navbar/check.svg"
                                                                                            style="width: 17px;margin-right: 8px;align-self: flex-start;" />
                                                                                        <span
                                                                                            style="white-space: break-spaces;">Features:
                                                                                            {{rowData.attributes.applicationFeatures}}</span>
                                                                                    </div>
                                                                                    <div class="p-p-1 p-text-left"
                                                                                        style="display: flex;height: 50px;">
                                                                                        <img alt=""
                                                                                            src="{{this.redirect}}navbar/check.svg"
                                                                                            style="width: 17px;margin-right: 8px;align-self: flex-start;" />
                                                                                        <span
                                                                                            style="white-space: break-spaces;">No
                                                                                            Of Applications Generated:
                                                                                            <br>{{rowData.noOfApplications}}</span>
                                                                                    </div>
                                                                                    <div class="p-p-1 p-text-left"
                                                                                        style="display: flex;">
                                                                                        <img alt=""
                                                                                            src="{{this.redirect}}navbar/check.svg"
                                                                                            style="width: 17px;margin-right: 8px;" />
                                                                                        <span>Ingress data Limit:
                                                                                            {{rowData.attributes.ingressDataLimit}}</span>
                                                                                    </div>
                                                                                    <div class="p-p-1 p-text-left"
                                                                                        style="display: flex;">
                                                                                        <img alt=""
                                                                                            src="{{this.redirect}}navbar/check.svg"
                                                                                            style="width: 17px;margin-right: 8px;" />
                                                                                        <span>Egress data Limit:
                                                                                            {{rowData.attributes.egressDataLimit}}</span>
                                                                                    </div>
                                                                                    <div class="p-p-1 p-text-left"
                                                                                        style="display: flex;">
                                                                                        <img alt=""
                                                                                            src="{{this.redirect}}navbar/check.svg"
                                                                                            style="width: 17px;margin-right: 8px;" />
                                                                                        <span>No. of request per user:
                                                                                            {{rowData.attributes.apiRequestsPerUser}}</span>
                                                                                    </div>
                                                                                    <div class="p-p-1 p-text-left"
                                                                                        style="height: 50px;display: flex;">
                                                                                        <img alt=""
                                                                                            src="{{this.redirect}}navbar/check.svg"
                                                                                            style="width: 17px;margin-right: 8px;align-self: flex-start;" />
                                                                                        <span>MFA:
                                                                                            {{rowData.attributes.MFA}}</span>
                                                                                    </div>
                                                                                    <div class="p-p-2 p-text-center">
                                                                                        <button pButton type="button"
                                                                                            label="Get Started"
                                                                                            class="p-button-secondary p-button-lg"
                                                                                            (click)="routeToRegister(rowData)"
                                                                                            style="height: 32px;font-size: 14px;width: 114px;">
                                                                                        </button>
                                                                                    </div>
                                                                                </div>

                                                                            </div>


                                                                        </div>
                                                                    </ng-template>
                                                                </p-card>
                                                            </tr>
                                                        </div>
                                                    </ng-template>
                                                    <ng-template pTemplate="emptymessage">
                                                        <tr style="width: auto;">
                                                            <td>No Plans Found.</td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                                <p-table #appdetailsid [value]="firstFeature" name="table"
                                                    styleClass="p-datatable-customers" scrollHeight="400px"
                                                    [scrollable]="true" [columns]="planDetails" [autoLayout]="true">
                                                    <ng-template pTemplate="header" let-columns>
                                                        <tr>
                                                            <th scope="col" *ngFor="let col of columns"
                                                                style="min-width: 250px;"
                                                                [ngClass]="col?.planName == 'Plan Details' ? 'thfeatureClass p-frozen-column' : 'rowClass'"
                                                                pFrozenColumn [frozen]="col?.planName=='Plan Details'">
                                                                <div style="font-size: 13px;">{{ col?.planName }}
                                                                    <img alt=""
                                                                        *ngIf="col?.planName!='Plan Details' && col?.planDescription!=null"
                                                                        pTooltip="{{col?.planDescription}}"
                                                                        tooltipPosition="top"
                                                                        src="{{this.redirect}}navbar/fi_alert.svg"
                                                                        style="width: 17px;margin-right: 8px;align-self: flex-start;" />
                                                                </div>
                                                                <div *ngIf="col?.planName!='Plan Details'"
                                                                    style="font-weight: 500;font-size: 16px;"
                                                                    class="p-mt-2">
                                                                    <span *ngIf="col?.attributes[0]?.value!=null">
                                                                        {{col?.attributes[0]?.value}}{{
                                                                        col?.attributes[0]?.symbol}}</span>
                                                                    <span
                                                                        *ngIf="col?.attributes[0]?.perUnitType=='Monthly' || col?.attributes[0]?.perUnitType=='Annual' || col?.attributes[0]?.perUnitType=='Daily'">
                                                                        /{{col?.attributes[0]?.perUnitType}}
                                                                    </span>
                                                                    <span
                                                                        *ngIf="col?.attributes[0]?.value==null">-</span>
                                                                    <br>
                                                                    <div class="btnwrap p-mt-2">
                                                                        <button pButton
                                                                            class="p-button-brand2 p-button hoverClass"
                                                                            type="button" label="Select"
                                                                            (click)="routeToRegister(col)">
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-rowData let-columns
                                                        let-index="rowIndex">
                                                        <tr *ngIf="index>0">
                                                            <td *ngFor="let cols of this.planDetails"
                                                                style="min-width: 250px;"
                                                                [frozen]="cols?.planName=='Plan Details'"
                                                                [ngClass]="cols?.planName == 'Plan Details' ? 'featureClass p-frozen-column' : 'rowClass'"
                                                                alignFrozen="left" pFrozenColumn>
                                                                <span
                                                                    *ngIf="cols?.planName=='Plan Details'">{{rowData.displayName
                                                                    }}
                                                                    <span
                                                                        *ngIf="rowData.symbol!=null">({{rowData.symbol}})</span>
                                                                </span>
                                                                <span *ngIf="cols?.planName!='Plan Details'">
                                                                    <span
                                                                        *ngIf="cols?.attributes[index]?.value!='false' && cols?.attributes[index]?.value!='true' && cols?.attributes[index]?.value!=null">
                                                                        {{cols?.attributes[index]?.value}}
                                                                    </span>
                                                                    <span
                                                                        *ngIf="cols?.attributes[index]?.perUnitType=='Monthly' || cols?.attributes[index]?.perUnitType=='Annual' || cols?.attributes[index]?.perUnitType=='Daily'">
                                                                        /{{cols?.attributes[index]?.perUnitType}}
                                                                    </span>
                                                                    <span
                                                                        *ngIf="cols?.attributes[index]?.value=='false' || cols?.attributes[index]?.value==null && index!=this.firstFeature.length-1">-</span>
                                                                    <span
                                                                        *ngIf="cols?.attributes[index]?.value=='true'">
                                                                        <img alt=""
                                                                            src="{{this.redirect}}navbar/Vector.svg"
                                                                            style="width: 17px" />
                                                                    </span>
                                                                    <span *ngIf="index==this.firstFeature.length-1">
                                                                        <span *ngFor="let fea of cols.features"
                                                                            style="min-width: 200px;">
                                                                            <span *ngIf="fea.featureName==null">
                                                                                -
                                                                            </span>
                                                                            <span *ngIf="fea.featureName!=null">
                                                                                {{fea.featureName}}
                                                                            </span>
                                                                            <img alt=""
                                                                                pTooltip="{{fea.featureDescription}}"
                                                                                tooltipPosition="top"
                                                                                *ngIf="fea.featureName!=null && fea.featureDescription!=null"
                                                                                src="{{this.redirect}}navbar/fi_alert.svg"
                                                                                style="width: 17px;margin-left: 5px;" />
                                                                            <br>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="emptymessage">
                                                        <tr style="width: auto;">
                                                            <td>No Plans Found.</td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                            </div>
                                        </div>
                                    </p-card>
                                </p-tabPanel>
                            </p-tabView>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>