import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse, HttpHandler, HttpEvent } from '@angular/common/http';
import { LoadingIndicatorService } from '../../loading-indicator.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  sessionId: string;
  constructor(private loadingIndicatorService: LoadingIndicatorService, private spinner: NgxSpinnerService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (localStorage.getItem("tenant") != null) {
      request = request.clone({
        setHeaders: {
          tenantId: localStorage.getItem("tenant")
        }
      });
    }

    if (localStorage.getItem("applicationId") != null) {
      request = request.clone({
        setHeaders: {
          applicationId: localStorage.getItem("applicationId")
        }
      });
    }

    if (localStorage.getItem("componentId") != null) {
      request = request.clone({
        setHeaders: {
          componentId: localStorage.getItem("componentId")
        }
      });
    }

    this.loadingIndicatorService.onStarted(request);
    return next.handle(request)
      .pipe(
        tap(
          (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              this.loadingIndicatorService.onFinished(request);
            }
          },
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              this.loadingIndicatorService.onFinished(request);
            }
          }
        )
      );
  }


}
