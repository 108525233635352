import { CiamLoginComponent } from './ciamlogin/ciamlogin.component';
import { ApplicationDetailComponent } from './ApplicationDetail/ApplicationDetail.component';
import { RegistrationComponent } from './registration/registration.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { APP_BASE_HREF } from '@angular/common';
import { ErrorComponent } from './error/error.component';
import { MarketplaceComponent } from './Marketplace/Marketplace.component';
import { CiamRouteComponent } from './ciamroute/ciamroute.component';

const routes: Routes = [
  { path: '', component: CiamRouteComponent },
  { path: 'login', component: CiamLoginComponent },
  { path: 'register', component: RegistrationComponent },
  { path: 'marketplace', component: MarketplaceComponent },
  { path: 'appdetail', component: ApplicationDetailComponent },
  { path: '**', component: ErrorComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
  ],
})
export class AppRoutingModule { }
