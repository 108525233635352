import { KEYCLOAK_API_URL, KEY_CLOAK_CLIENT_ID, KEY_CLOAK_REALM_NAME, LOGOUT_METHOD, REDIRECT_URI } from "./../app.constant";
import { Component, OnInit, OnChanges, ViewChild, ElementRef } from "@angular/core";
import { KeycloakEventType, KeycloakService } from "keycloak-angular";
import { AppMenu } from "../model/app-menu";
import { Router, NavigationEnd, NavigationExtras, ActivatedRoute } from "@angular/router";
import { HomedashboardService } from "./../homedashboard/homedashboard.service";
import { PrimaryNavService } from "./primary-nav.service";
import { MenuItem } from "primeng/api";
import { Location } from "@angular/common";
import { FULKRUM_WORKSPACE_URL, ACTIVE_PROJECT, SESSION_CHECK_TIMER, IDLE_SCREEN_LOGOUT } from "../app.constant";
import { BnNgIdleService } from "bn-ng-idle";
import { interval, Subject, Subscription, timer } from "rxjs";
import { environment } from "src/environments/environment";
import { UntypedFormBuilder } from '@angular/forms';
import { PagesService } from '../pages.service';
import { CiamLoginComponent } from "../ciamlogin/ciamlogin.component";
@Component({
  selector: "navbar-primary-nav",
  templateUrl: "./primary-nav.component.html",
  styleUrls: ["./primary-nav.component.css"],
})
export class PrimaryNavComponent implements OnInit, OnChanges {
  @ViewChild("deleteEntityModal")
  NavbarForm = this.fb.group({
    tenantList: [null, []],
  });
  deleteEntityModal: ElementRef;
  display: boolean = false;
  sessionId: string;
  username: string;
  items: MenuItem[];
  useritems: MenuItem[];
  multipleSessionModal: boolean = false;
  initials: any;
  userFullName12: string;
  userEmail: any;
  crossBtn: boolean = false;
  sublogo: boolean = true;
  activeProjectIsWorkspace = false;
  activeProjectIsCiam = false;
  BASE_URL: string = FULKRUM_WORKSPACE_URL + 'microui/'
  loginStatus: any;
  displayifLoggedIn: boolean;
  mySubscription: Subscription;
  tenantListList: any[] = [];
  selectedTenant: any;
  tenantDropCiamFlag: boolean = false;
  tenantDropSuperFlag: boolean = false;
  profileInfo: any;
  userID: any;
  lastLoggedDate: any;
  alreadyLogin: boolean = false;
  applicationId: any;
  componentId: any;
  appName: any;
  eventflag: boolean;
  dropDisable: boolean = false;
  roleMigrationFlag: string;
  rebacFlag: string;
  mainHref: boolean;
  realm: string;
  issuer: string;
  clientId: string;
  multiTenancyFlag: string;
  tenantSwitch: boolean = false;

  constructor(
    private keycloakService: KeycloakService,
    private router: Router,
    private homedashboard: HomedashboardService,
    private primaryNavService: PrimaryNavService,
    private _location: Location,
    private bnIdle: BnNgIdleService,
    private fb: UntypedFormBuilder,
    private keycloak: KeycloakService,
    private pagesService: PagesService,
    private route: ActivatedRoute
  ) {


    this.mySubscription = interval(SESSION_CHECK_TIMER).subscribe((x => {
      if (this.pagesService.alreadyLogin) {
        this.validateSessions();
      }
    }));

  }

  public rightactivepravin: boolean = false;
  output: JSON;

  private authoritiesArray: string[] = [];
  apps: AppMenu[];

  ngOnChanges() {
    console.log("");
  }

  userFullName: string;
  userRoles: string[];
  showDashboard: boolean = false;
  showDI: boolean = false;
  showCubix: boolean = false;
  showDesigner: boolean = false;
  showAdmin: boolean = false;
  showTransformix: boolean = false;
  haveAnyRole: any = "false";
  userFullName1: string;
  userFullName2: string;
  userName: string;

  counterSubject = new Subject<number>();
  currentTabIdle: boolean;
  idleChanges: Subscription;
  tabChanges: Subscription;
  popupOpenOtherTab;
  popupOpenCurrentTab: boolean = false;
  sessionUpdateTime: number;
  updateInterval;
  timerSub;
  counter;
  redirect: any;
  active_project: any;

  async ngOnInit() {
    console.log(location);
    this.multiTenancyFlag = environment.MULTI_TENANCY_ENABLE + ""
    this.roleMigrationFlag = environment.CIAM_NAVBAR_ROLE_MIGRATION_FLAG + "";
    this.rebacFlag = environment.REBAC_UM_ENTITLEMENT_FLAG + "";
    this.sessionId = localStorage.getItem("sessionId");
    if (this.sessionId === "undefined") {
      this.alreadyLogin = false;
      this.pagesService.alreadyLogin = false;
    }
    else {
      this.keycloakService?.loadUserProfile()?.then(response => {
        const account = response;
        if (account) {
          account['authorities'] = this.keycloakService.getUserRoles();
          localStorage.setItem("profile", JSON.stringify(account));
          localStorage.setItem("username", this.keycloakService?.getUsername());
          localStorage.setItem("count", "0");
        }
        //if (roleMigrationFlag.toString() == "true") {
        this.getTenantList();
        //}
      });

      this.userName = localStorage?.getItem("username");
      this.alreadyLogin = true;
      this.pagesService.alreadyLogin = true;
    }
    this.redirect = this.pagesService.redirect;
    this.active_project = ACTIVE_PROJECT;
    this.activeProjectIsCiam = true;
    this.activeProjectIsWorkspace = false;


    this.bnIdle.startWatching(IDLE_SCREEN_LOGOUT).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        if (this.alreadyLogin) {
          this.showDialog(); // 19 min
        }
      }
    });

    this.userName = localStorage.getItem("username");
    if (this.alreadyLogin) {
      this.checkUserSessions();
    }
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.eventflag = true;
      }
    });

    this.items = [{}];
    //if (roleMigrationFlag.toString() == "false") {
    //await this.getData_new();
    //}


  }

  showDialog() {
    this.popupOpenCurrentTab = true;
    clearInterval(this.updateInterval);
    this.display = true;
    this.sessionUpdateTime = 60;
    const source = timer(1000, 1000);
    this.timerSub = source.subscribe(timerSec => {
      if (this.sessionUpdateTime > 0) {
        this.sessionUpdateTime = this.sessionUpdateTime - 1;
        this.counterSubject.next(this.sessionUpdateTime);
      }
      if (this.sessionUpdateTime == 0) {
        this.timerSub.unsubscribe();
        this.display = false;
        this.logout();
      }
    });
    this.counterSubject.subscribe(value => {
      this.counter = value;
    });
  }

  accept() {
    this.timerSub.unsubscribe();
    this.display = false;
    this.keycloakService.updateToken(5);
    this.router.navigate(["/ciam"]);
  }

  reject() {
    this.logout();
    this.display = false;
  }

  fullname: string;
  result: any;
  uname: string;
  lobs: any;
  roles: string;
  lobdata: any;
  userData: any;
  accessFilterList: string[];

  isSuccess: boolean;
  isFailure: boolean;
  displayFailureMessage = "";

  logout() {
    sessionStorage.removeItem('previousUrl');
    if(LOGOUT_METHOD=='POST'){
      const options : any = {
        redirectUri : location.origin + REDIRECT_URI,
        logoutMethod : 'POST'
      }
      this.keycloakService.getKeycloakInstance().logout(options);
      window.location.href=options.redirectUri;
      this.pagesService.alreadyLogin = false;
      this.displayifLoggedIn = false;
    }
    else{
      this.keycloakService.logout(location.origin + REDIRECT_URI);
      this.pagesService.alreadyLogin = false;
      // this.router.navigate(["/marketplace"]);
      this.displayifLoggedIn = false;
    }
    
  }


  cancel() {
    this.multipleSessionModal = false;
    this.keycloakService.logout(location.origin + REDIRECT_URI);
    this.pagesService.alreadyLogin = false;
  }
  allOtherSessionLogout() {
    this.primaryNavService.getkillSessions().subscribe((resp) => {
    })
    this.displayifLoggedIn = false;
  }

  validateSessions() {
    this.primaryNavService.validateSessions().subscribe(response => {
      if (response) {
        this.logout();
        // window.location.reload();
      }
    }, err => {
    });
  }

  checkUserSessions() {
    this.primaryNavService.getUserSessions().subscribe((resp) => {
      this.loginStatus = resp;
      if (this.loginStatus === true) {
        this.displayifLoggedIn = true;
      }
    })
  }



  toHelpFAQ(): void {

    this.router.navigate(['/workspace/pages/helpfaqpage']);

  }

  onChangePassword(): void {
    const navigationExtras: NavigationExtras = {
      state: {
        changePassword: true,
      },
    };
    this.router.navigate(['ciam/pages/myaccount'], navigationExtras);

  }

  redirectToProfile() {
    this.router.navigate(['ciam/pages/myaccount'])
  }

  ldapFlag: any
  setPwdFlag: boolean
  getValue() {
    const uname = JSON.parse(localStorage.getItem('profile'));
    this.ldapFlag = uname.attributes.LDAP_ENTRY_DN;
    if (this.ldapFlag !== undefined) {
      this.setPwdFlag = false;
    }
    else {
      this.setPwdFlag = true
    }
    return uname.username;
  }

  roleAuth: boolean = false;
  roleSuperAdmin: boolean = false;
  roleTenantAdmin: boolean = false;
  roleCiamAdmin: boolean = false;
  roleCiamTenantAdmin: boolean = false;
  showCiamResource: boolean = false;
  showMyWorkplace: boolean = false;

  getData_new() {

    this.keycloakService.loadUserProfile().then((response) => {
      const account = response;
      if (account) {
        account["authorities"] = this.keycloakService.getUserRoles();
        this.userFullName1 = (
          account["firstName"] +
          " " +
          account["lastName"]
        );
        this.userEmail = account["email"];
      }
      const roleMigrationFlag = environment.CIAM_NAVBAR_ROLE_MIGRATION_FLAG + "";
      //call get user details
      this.getApplicationRoles();
      // if (roleMigrationFlag.toString() == "true") {
      //   const appRole = this.keycloakService.getKeycloakInstance().resourceAccess[this.appName];
      //   if (appRole == undefined) {
      //     this.userRoles = [];
      //   }
      //   else {
      //     this.userRoles = this.keycloakService.getKeycloakInstance().resourceAccess[this.appName]?.roles;
      //   }

      // }
      // else {
      //   this.userRoles = this.keycloakService.getKeycloakInstance().realmAccess.roles;

      // }

      localStorage.setItem("profile", JSON.stringify(account));
      localStorage.setItem("username", this.keycloakService.getUsername());
      localStorage.setItem("count", "0");
      // if (roleMigrationFlag.toString() == "true") {
      //   this.getLastLoggedIn();
      // }
      // if (roleMigrationFlag.toString() == "false" && this.alreadyLogin) {
      //   this.getTenantList();
      // }
      this.uname = this.keycloakService.getUsername();

      if (typeof account["firstName"] === "undefined") {
        let fname = "";
        this.userFullName = fname + " " + account["lastName"];
      } else {
        this.userFullName = account["firstName"] + " " + account["lastName"];
      }
      let userName =
        account["firstName"].charAt(0) + "" + account["lastName"].charAt(0);
      this.initials = userName;

      this.userFullName = userName.split(" ").join("");
    });

  }


  enableCrossBtn() {
    this.crossBtn = true;
  }

  submenucheck() {
    setTimeout(() => {
      const elArr = document.querySelector(".p-slidemenu-wrapper "); // all
      if (!elArr) {
        this.crossBtn = false;
      }
      const submenu = document.querySelector(
        ".p-slidemenu-rootlist .p-active-submenu"
      ); // all
      if (!submenu) {
        this.sublogo = true;
      } else {
        this.sublogo = false;
      }
    }, 150);
  }

  routerLinkFunc(url: any) {
    this.router.navigate([url]);
  }
  getTenantList(): void {
    this.tenantListList = [];
    this.userName = localStorage.getItem("username");
    const data = this.userName;
    this.primaryNavService.getTenantListByUsername(data).subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData?.length > 0) {
        this.tenantListList = [];
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          if (data.label == environment.keycloak.realm) {
            localStorage.setItem("masterTenantId", data.value);
          }
          this.tenantListList.push(data);
        });
        this.tenantListList = [...this.tenantListList];
        if (this.tenantListList.length == 1) {
          this.dropDisable = true;
          document.getElementById('tenantList').classList.add('disabelDrop');
        }
        this.selectedTenant = this.tenantListList[0].value;
        for (let i = 0; i < this.tenantListList?.length; i++) {
          if (this.selectedTenant == this.tenantListList[i].value) {
            localStorage.setItem("tenantName", this.tenantListList[i].label);
          }
        }
        localStorage.setItem("tenant", this.selectedTenant);
        localStorage.setItem("navbarFlag", 'Yes');
        const roleMigrationFlag = environment.CIAM_NAVBAR_ROLE_MIGRATION_FLAG + "";
        if (roleMigrationFlag.toString() == "true") {
          this.getCiamApplicationDetails();
          if(this.rebacFlag=="false" ){
            this.getLastLoggedIn();
          }
        }
        else if (roleMigrationFlag.toString() == "false") {
          this.getData_new();
         if(this.rebacFlag=="false" ){
            this.getLastLoggedIn();
          }
        }
        this.router.navigate(["/ciam"]);
      }
    },
      error => {
        if (error.error.error?.code == "404") {
          this.logout();
        }
      }
    );
  }

  sendTenantID() {
    console.log("selectedtenant", this.selectedTenant);
    for (let i = 0; i < this.tenantListList?.length; i++) {
      if (this.selectedTenant == this.tenantListList[i].value) {
        localStorage.setItem("tenantName", this.tenantListList[i].label);
      }
    }
    localStorage.setItem("tenant", this.selectedTenant);
    this.tenantSwitch = true;
    this.getCiamApplicationDetails();
  }
  getLastLoggedIn() {
    this.profileInfo = JSON.parse(localStorage.getItem('profile'));
    let tenantId = localStorage.getItem("tenant");
    let reqBody = {};
    reqBody['tenantId'] = localStorage.getItem("tenant");
    reqBody['username'] = this.keycloakService.getUsername();
    const formValues = reqBody;
    this.primaryNavService.getUserDetails(formValues).subscribe((results: any) => {
      this.userID = results?.success?.result?.userId;
      this.primaryNavService.getLastLoggedByUserId(this.userID, tenantId).subscribe((results: any) => {
        this.lastLoggedDate = results.success.result;
        localStorage.setItem("lastloggedIn", this.lastLoggedDate);
      });
    });
  }
  getCiamApplicationDetails() {
    let tenantId = localStorage.getItem("tenant");
    this.primaryNavService.getCiamApplicationDetails(tenantId).subscribe((results: any) => {
      this.appName = results.success.result.applicationName;
      this.applicationId = results.success.result.applicationId;
      this.componentId = results.success.result.componentId;
      localStorage.setItem("applicationId", this.applicationId);
      localStorage.setItem("componentId", this.componentId);
      if (this.tenantSwitch) {
        this.tenantSwitch = false;
        if (location.href.slice(-5).includes("ciam")) {
          this.router.navigate(['ciam/pages/myaccount']);
        }
        else {
          this.router.navigate(["/ciam"]);
        }
      }
      else {
        this.getData_new();
      }
    });
  }
  getApplicationRoles() {
    this.userRoles = [];
    this.items = [];
    this.uname = JSON.parse(localStorage.getItem('profile'));
    let uid = this.username;
    let reqBody = {};
    reqBody['tenantId'] = localStorage.getItem("tenant");
    reqBody['username'] = this.keycloakService.getUsername();
    const formValues = reqBody;
    this.primaryNavService.getUserDetails(formValues).subscribe((results: any) => {
      if (this.roleMigrationFlag == "false") { //tenant roles
        let tenantRoles = results.success.result.tenantLevelRoles;
        if (tenantRoles && tenantRoles.length > 0) {
          tenantRoles.forEach((element1: any = {}) => {
            this.userRoles.push(element1.roleName);
          });
          this.userRoles = [...this.userRoles];
        }
      }
      else if (this.roleMigrationFlag == "true") { //app based roles
        let application = results.success.result.application;
        application.forEach((element: any = {}) => {
          if (element.applicationName == this.appName) {
            let appRoles = element.applicationLevelRoles;
            if (appRoles && appRoles.length > 0) {
              appRoles.forEach((element1: any = {}) => {

                this.userRoles.push(element1.roleName);
              });
              this.userRoles = [...this.userRoles];
            }
          }
        });

      }
      console.log("migration:" + this.roleMigrationFlag);
      this.userRoles.forEach((role) => {
        if ((this.roleMigrationFlag.toString() == "false" && role == "ROLE_AUTH") || (this.roleMigrationFlag.toString() == "true" && role == "ROLE_CIAM_AUTH")) {
          this.roleAuth = true;
        }
        else if (role == "ROLE_TENANT_ADMIN") {
          this.roleTenantAdmin = true;
        } else if (role == "ROLE_CIAM_ADMIN") {
          this.tenantDropCiamFlag = true;
          this.roleCiamAdmin = true;
        } else if (role == "ROLE_CIAM_TENANT_ADMIN") {
          this.roleCiamTenantAdmin = true;
        } else if (role == "ROLE_CIAM_APP_ADMIN") {
          this.showCiamResource = true;
        } else if (role == "ROLE_CIAM_SUPER_ADMIN" || role == "ROLE_SUPER_ADMIN") {
          this.tenantDropSuperFlag = true;
          this.roleAuth = true;
          this.showCiamResource = true;
        }
      });

      if (!this.roleAuth) {
        this.logout();
      }



    });


  }
  loginRoute() {
    console.log("in primary loginroute");
    if (this.multiTenancyFlag == "true") {
      CiamLoginComponent.realm = location?.hostname.slice(0, location?.hostname.indexOf('.'));
    }
    else {
      CiamLoginComponent.realm = KEY_CLOAK_REALM_NAME;
    }
    console.log("realm " + CiamLoginComponent.realm);
    this.keycloak.init({
      config: {
        url: KEYCLOAK_API_URL,
        realm: CiamLoginComponent.realm,
        clientId: KEY_CLOAK_CLIENT_ID
      },
      loadUserProfileAtStartUp: false,
      initOptions: {
        onLoad: 'login-required',
        checkLoginIframe: false,
      },
      enableBearerInterceptor: true,

      bearerExcludedUrls: [],
      bearerPrefix: 'Bearer'
    });
    this.keycloak.keycloakEvents$.subscribe({
      next: (e) => {
        if (e.type == KeycloakEventType.OnTokenExpired) {
          this.keycloak.updateToken(30);
        }
      }
    });
  }
}
