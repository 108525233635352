<style>
  .layout-footer-3 {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 40px;
    padding: 0;
    background: rgba(255, 255, 255, 1);
  }

  .layout-footer-3 .p-tabview {
    display: flex;
  }

  .layout-footer-3 .p-tabview .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav li {
    position: relative;
  }

  .layout-footer-3 .p-tabview .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav li::before {
    content: "";
    position: absolute;
    bottom: 0;
    background: #E9EAED;
    width: 10px;
    height: 10px;
    left: -10px;
  }

  .layout-footer-3 .p-tabview .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav li::after {
    content: "";
    position: absolute;
    bottom: 0;
    background: #E9EAED;
    width: 10px;
    height: 10px;
    right: -10px;
  }

  .layout-footer-3 .p-tabview .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav li .p-tabview-nav-link {
    float: left;
    padding: 10px 40px !important;
    text-decoration: none;
    color: #4A6DA8 !important;
    background: #E9EAED !important;
    border-radius: 10px 10px 0 0;
  }

  .layout-footer-3 .p-tabview .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav .p-highlight {
    z-index: 3;
  }

  .layout-footer-3 .p-tabview-nav-btn {
    position: absolute;
  }
</style>
<div style="position: relative;z-index: 9;">
  <div class="layout-footer-3" style="box-shadow: 1px 0px 3px rgb(145 151 165 / 50%);">
    <div class="p-grid" style="height: 100%;">
      <div class="p-col">

      </div>
      <div class="p-col-fixed p-text-right" style="padding-top: 12px;">
        <small style="display: flex; float: right;">Copyright © 2022 CRISIL

        </small>
      </div>
    </div>
  </div>
</div>