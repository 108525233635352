import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { KEY_CLOAK_CLIENT_ID, KEYCLOAK_API_URL, KEY_CLOAK_REALM_NAME } from '../app.constant';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { PagesService } from '../pages.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'jhi-ciamlogin',
  templateUrl: './ciamlogin.component.html',
  styleUrls: ['./ciamlogin.component.scss'],
})
export class CiamLoginComponent implements OnInit {

  defaultValue: boolean = false;
  pageloadvalues: any;
  columns_1_0_columns_1_0_carousel_1_1List: any[] =
    [{
      "abc": 'abc',
    }]
    ;
  clipPropertypersonalDetails: string = '';
  clipPropertyaccountDetails: string = '';
  accounttype: any;
  accountTypeList: { label: string; value: string; }[];
  accountTypeselectedTypes: string[];
  switchScreen: boolean = false;
  switchScreen1: boolean;
  product: { image: string; description: string; detail: string; }[];
  data: any;
  display: string;
  btnFlag: boolean = false;
  resendFlag: boolean = false;
  otp: any;
  id: any;
  organizationName: any;
  domainEditFlag: boolean = true;
  domain: any;
  routeData: any;
  appId: any;
  applicationName: any;
  planId: any;
  messageFlag: boolean = false;
  email: any;
  maskemail: any;
  maskedEmail: string;
  alreadyLogin: boolean = false;
  mainHref: boolean;
  static realm: string;
  issuer: string;
  clientId: string;
  multiTenancyFlag: string;

  constructor(private router: Router, protected activatedRoute: ActivatedRoute, private fb: UntypedFormBuilder, private keycloak: KeycloakService, private pagesService: PagesService) {
    const navigation = this.router.getCurrentNavigation();
    this.routeData = navigation?.extras?.state;
  }
  ngOnInit(): void {
    this.multiTenancyFlag = environment.MULTI_TENANCY_ENABLE + ""
    this.alreadyLogin = this.pagesService.alreadyLogin;
    if (location.href.slice(-5) == 'login' && !this.alreadyLogin) {
      this.loginRoute();
    }
    else if (this.alreadyLogin) {
      this.router.navigate(["/ciam"]);
    }
  }

  loginRoute() {
    console.log("in loginroute");
    if (this.multiTenancyFlag == "true") {
      CiamLoginComponent.realm = location?.hostname.slice(0, location?.hostname.indexOf('.'));
    }
    else {
      CiamLoginComponent.realm = KEY_CLOAK_REALM_NAME;
    }
    console.log("realm " + CiamLoginComponent.realm);
    this.keycloak.init({
      config: {
        url: KEYCLOAK_API_URL,
        realm: CiamLoginComponent.realm,
        clientId: KEY_CLOAK_CLIENT_ID
      },
      loadUserProfileAtStartUp: false,
      initOptions: {
        onLoad: 'login-required',
        checkLoginIframe: false,
      },
      enableBearerInterceptor: true,

      bearerExcludedUrls: [],
      bearerPrefix: 'Bearer'
    });
    this.keycloak.keycloakEvents$.subscribe({
      next: (e) => {
        if (e.type == KeycloakEventType.OnTokenExpired) {
          this.keycloak.updateToken(30);
        }
      }
    });
  }
}