<ngx-spinner size="medium" type="ball-grid-beat">
    <p style="color: white;margin-top: 100px;">
        Loading...
    </p>
</ngx-spinner>

<navbar-primary-nav></navbar-primary-nav>

<router-outlet></router-outlet>

<jhi-footer style="z-index: 9;"></jhi-footer>