import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { KeycloakService } from 'keycloak-angular';
import { PagesService } from '../pages.service';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'jhi-ciamroute',
  templateUrl: './ciamroute.component.html',
  providers: [MessageService],
})
export class CiamRouteComponent implements OnInit {

  defaultValue: boolean = false;
  pageloadvalues: any;
  columns_1_0_columns_1_0_carousel_1_1List: any[] =
    [{
      "abc": 'abc',
    }]
    ;
  clipPropertypersonalDetails: string = '';
  clipPropertyaccountDetails: string = '';
  accounttype: any;
  accountTypeList: { label: string; value: string; }[];
  accountTypeselectedTypes: string[];
  switchScreen: boolean = false;
  switchScreen1: boolean;
  product: { image: string; description: string; detail: string; }[];
  data: any;
  display: string;
  btnFlag: boolean = false;
  resendFlag: boolean = false;
  otp: any;
  id: any;
  organizationName: any;
  domainEditFlag: boolean = true;
  domain: any;
  routeData: any;
  appId: any;
  applicationName: any;
  planId: any;
  messageFlag: boolean = false;
  email: any;
  maskemail: any;
  maskedEmail: string;
  saasEnabledFlag: boolean = true;

  constructor(private router: Router, protected activatedRoute: ActivatedRoute, private fb: UntypedFormBuilder, private keycloak: KeycloakService, private pagesService: PagesService, private messageService: MessageService) {
    const navigation = this.router.getCurrentNavigation();
    this.routeData = navigation?.extras?.state;
  }
  ngOnInit(): void {
    const saasFlag = environment.IS_SAAS_ENABLED + "";
    if (!this.pagesService.alreadyLogin) {
      if (saasFlag.toString() == "true") {
        this.router.navigate(["/marketplace"]);
      } else {
        this.router.navigate(["/login"]);
      }
    }
    else {
      this.router.navigate(["/ciam"]);
    }
  }
}