import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { PagesService } from '../pages.service';
import { ConfirmationService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { REDIRECT_URI } from '../app.constant';
@Component({
  selector: 'jhi-applicationdetail',
  templateUrl: './ApplicationDetail.component.html',
  styleUrls: ['./ApplicationDetail.component.scss'],
  providers: [ConfirmationService]
})
export class ApplicationDetailComponent implements OnInit {
  hasCode = false;
  ApplicationDetailForm = this.fb.group({
    selectSwitch: [null],
    tabView: ['tab1'],
    tabView_0_columns_1_2_ccprimeinputtext_2: [null],
    tabView_1_columns_1_0_ccprimebuttongroup_1: [null],
    accountType: [null],
  }
  );
  defaultValue: boolean = false;
  pageloadvalues: any;
  breadcrumbTopList = [
    { label: '', routerLink: ['/pages/applicationdetail'] },
  ]
  homeiconbreadcrumbTop = { icon: 'pi pi-home', routerLink: ['/marketplace'] };
  clipPropertytabView_0_columns_1_0_card_1_columns_1_0_columns_1_2_ccparagraph_1: string = '';
  clipPropertytabView_0_columns_1_1_ccprimeheading_1: string = '';
  tabView_1_columns_1_0_ccprimebuttongroup_1List: any[] = [{ 'label': "Monthly", 'value': 'monthly' }, { 'label': "Yearly", 'value': 'yearly' },];
  tabView_1_columns_1_0_ccprimebuttongroup_1selectedTypes: string = this.tabView_1_columns_1_0_ccprimebuttongroup_1List.length ? this.tabView_1_columns_1_0_ccprimebuttongroup_1List[0].value : '1'
  description: any;
  parsedData: any;
  tableList: any;
  viewMore: boolean = true;
  viewToggleName: string;
  viewToggleIcon: string;
  applicationName: any;
  accountTypeList: { label: string; value: string; }[];
  accountTypeselectedTypes: string;
  planList: any;
  appId: any;
  redirect: string;
  appName: any;
  small: any = "smaller";
  onButtonClick: boolean = false;
  myron: any;
  selectSwitchList: any[] = [{ 'label': "Monthly", 'value': 'monthly' }, { 'label': "Yearly", 'value': 'yearly' },];
  defaultselect_selectSwitch: any;
  // Myron:any="Myron";
  imagePath: string;
  checkImage: string;
  myronVideo: string;
  redirectNav: string;
  colors: any = ['p-avatar-color1', 'p-avatar-color2', 'p-avatar-color3', 'p-avatar-color4', 'p-avatar-color5', 'p-avatar-color6', 'p-avatar-color7', 'p-avatar-color8'];
  productfeatures: { featureName: string; description: string; saasApplication: boolean; deleted: boolean; }[];
  tableList1: any;
  errorFlag: boolean;
  deafultAttributes: any;
  planDetails: any;
  firstFeatureOne: any;
  firstFeature: any;
  timeList: { label: string; value: string; }[];
  timeSelectedTypes: any;
  planCount: number;
  logoFilePath: any;
  videoFilePath: any;
  imageURL: File;
  videoURL: File;
  BASE_URL: string = this.pagesService.redirect + "ciamservice/ciam/v1/lookups/files/";
  showDefaultLogo: boolean = true;
  showDefaultVideo: boolean = true;

  constructor(private router: Router, protected activatedRoute: ActivatedRoute, private fb: UntypedFormBuilder, private pagesService: PagesService, private confirmationService: ConfirmationService) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    this.parsedData = data;
    this.timeList = [{ label: "Monthly", value: 'monthly' }, { label: "Yearly", value: 'yearly' }];
    this.timeSelectedTypes = "monthly";

  }
  ngOnInit(): void {
    this.imagePath = environment.IMAGE_PATH;
    this.redirect = this.pagesService.redirect;
    this.checkImage = this.imagePath + "/check.svg";
    this.myronVideo = this.imagePath + "/Myron%20Video.mp4"
    if (this.pagesService.alreadyLogin) {
      this.router.navigate(["/ciam"]);
    }
    else {
      this.router.navigate(["/appdetail"]);
    }
    this.description = this.parsedData.description;
    this.appName = this.parsedData.applicationName;
    this.appId = this.parsedData.id;
    this.logoFilePath = this.parsedData.logoFilePath;
    this.videoURL = this.parsedData.videoFilePath;
    this.imageURL = this.parsedData.logoFilePath;
    if (this.imageURL != null) {
      this.getApplicationLogo(this.imageURL);
    }
    if (this.videoURL != null) {
      this.getApplicationVideo(this.videoURL);
    }
    this.accountTypeList = [{ 'label': "Monthly", 'value': 'monthly' }, { 'label': "Yearly", 'value': 'yearly' },];
    this.accountTypeselectedTypes = "monthly";
    this.description = this.parsedData.description;
    this.applicationName = this.parsedData.applicationName;
    this.appId = this.parsedData.id;
    this.breadcrumbTopList = [];
    this.breadcrumbTopList.push({
      label: this.parsedData.applicationName,
      routerLink: []
    });

    this.gettableList();
    this.getAllPlanAttributes();
    this.myron = this.applicationName.toLowerCase();
    this.productfeatures = [
      {
        "featureName": "Easy Drag-and-drop",
        "description": "Traditional designing and development scenario was included of typing long complex codes while, Low Code No code reduces much time in application development duration. Complete full fledge working application can be achieved with LCNC (Low-Code and No-Code) feature of simple drag-and-drop of ready to use components.",
        "saasApplication": true,
        "deleted": false
      },
      {
        "featureName": "Device Compatible",
        "description": "Using LCNC platform it is possible to develop and design such an application which will be compatible with any type of mobile device. It could be possible with the cross-platform compatibility. And it is the most demanding feature of any application for this cellular generation.",
        "saasApplication": true,
        "deleted": false
      },
      {
        "featureName": "Easy Alteration Process",
        "description": "In LCNC platform it’s easier to perform alteration in the application. Nowadays clients expect alterations and customization service with the speed of light. With Myron application codes are totally avoided or very less needed and can be done with in short period with rectified manual interaction.",
        "saasApplication": true,
        "deleted": false
      },
      {
        "featureName": "Scalable and secure",
        "description": "Server and system hacking is general nowadays which can lead to a big loss.  Myron LCNC platform is much more secure comparing to other platforms and it should be because it is the most essential feature of any application.",
        "saasApplication": true,
        "deleted": false
      },
      {
        "featureName": "Improved Efficiency",
        "description": "No-code and low-code apps are good for enhancing everyday task efficiency because they need less development effort. IT units can address their own operational difficulties without needing many coders if they can build their own versions of familiar apps or swiftly install much-needed functionalities.",
        "saasApplication": true,
        "deleted": false
      },
      {
        "featureName": "Promotes Innovation",
        "description": "Developers can use LCNC to demonstrate their ideas quickly and can be ramped up during implementation. Instead of simply providing theory, a developer can demonstrate how the project would shape up in order to gain executive buy-in and persuade management to devote more development resources to the initiative. The innovation using LCNC is further compounded with Degree of Freedom and Tenancy Extension features.",
        "saasApplication": true,
        "deleted": false
      }
    ]
    this.onClipConvertingtabView_0_columns_1_0_card_1_columns_1_0_columns_1_2_ccparagraph_1();
    this.onClipConvertingtabView_0_columns_1_1_ccprimeheading_1();
  }
  getApplicationLogo(filePath: any) {
    this.pagesService.applicationLogo(filePath).subscribe((results: any) => {
      this.imageURL = results.logo;
      this.showDefaultLogo = false;
    },
      error => {
        if (error.status == 200) {
          this.showDefaultLogo = false;
        }
        else {
          this.showDefaultLogo = true;
        }
      });
  }

  getApplicationVideo(filePath: any) {
    this.pagesService.applicationLogo(filePath).subscribe((results: any) => {
      this.videoURL = results.logo;
      this.showDefaultVideo = false;
    },
      error => {
        if (error.status == 200) {
          this.showDefaultVideo = false;
        }
        else {
          this.showDefaultVideo = true;
        }
      });
  }

  getColor(i: any) {
    let count = 0;
    if (i < this.colors?.length) {
      return this.colors[i]
    }

    if (i >= this.colors?.length) {
      for (let j = 0; j <= i; j++) {
        if (j % this.colors?.length == 0 && j != 0) {
          count++;
        }
      }
      return this.colors[i - (this.colors?.length * count)]
    }
  }
  gettableList(): void {
    this.pagesService.getAppList().subscribe(results => {
      const responseData = results?.success?.result;
      if (responseData && responseData?.length > 0) {
        this.tableList = responseData;
      }
    });
  }

  getPlanList() {
    this.planCount = 0;
    this.planDetails = [];
    let reqBody = {};
    reqBody['applicationId'] = this.appId;
    const formValues = reqBody;
    this.pagesService.getPlanListByAppId(formValues).subscribe((results: any) => {
      const responseData = results?.success?.result.plans;
      responseData.sort((first, second) => {
        // return 0, positive, or negative value according to difference of the year
        // If difference is positive, sort() method will swap the objects, otherwise not.
        // If users want to sort array in the decreasing order, just return second.joinedYear - first.joinedYear
        return first.orderNumber - second.orderNumber;
      });
      if (responseData && responseData.length > 0) {
        this.planDetails = responseData;
        this.planCount = results?.success?.result.count;
        this.planDetails.splice(0, 0, this.firstFeatureOne[0]);
        this.firstFeature = this.planDetails[0].attributes;
      }
    });
  }

  getAllPlanAttributes() {
    this.deafultAttributes = [];
    this.pagesService.getAllPlanAttributes().subscribe((results: any) => {
      const responseData = results?.success?.result;
      if (responseData && responseData.length > 0) {
        this.deafultAttributes = responseData;
        this.deafultAttributes.push({ "displayName": "Features List" });
        this.firstFeatureOne = [
          {
            "id": "planId",
            "planName": "Plan Details",
            "createdBy": "niveditab",
            "availableSubscription": "0",
            "attributes": this.deafultAttributes
          }
        ];
      }
      this.getPlanList();
    });
  }


  routeToRegister(rowData: any) {
    const navigationExtras: NavigationExtras = {
      state: {
        planId: rowData.id,
        appName: this.appName,
        appId: this.appId
      },
    };
    this.router.navigate(['/register'], navigationExtras);
  }

  handleChangetabView(e: any): void {
    this.ApplicationDetailForm.patchValue({ "tabView": e.index });
  }

  onClipConvertingtabView_0_columns_1_0_card_1_columns_1_0_columns_1_2_ccparagraph_1() {
    let title = 'Myron is a web application tool that allows users to create and deploy applications without writing extensive amounts of code. A visual drag-and-drop interface, pre-built templates and components, and other features that simplify the development process.'
    this.clipPropertytabView_0_columns_1_0_card_1_columns_1_0_columns_1_2_ccparagraph_1 = title.substring(0, +1) + '...';
  }

  onClipConvertingtabView_0_columns_1_1_ccprimeheading_1() {
    let title = 'Product Features'
    this.clipPropertytabView_0_columns_1_1_ccprimeheading_1 = title.substring(0, +1) + '...';
  }

  removepselectchiptabView_1_columns_1_0_ccprimebuttongroup_1Chip(item: any) {
    this.tabView_1_columns_1_0_ccprimebuttongroup_1List.splice(this.tabView_1_columns_1_0_ccprimebuttongroup_1List.indexOf(item), 1);
  }

  tickpselectchiptabView_1_columns_1_0_ccprimebuttongroup_1Chip(item: any) {
    this.tabView_1_columns_1_0_ccprimebuttongroup_1List.slice(this.tabView_1_columns_1_0_ccprimebuttongroup_1List.indexOf(item), 1);
  }
}

