
import { environment } from 'src/environments/environment';

export let REDIRECT_URI = environment.REDIRECT_URI;
export let KEYCLOAK_API_URL = environment.keycloak.issuer;
export let KEY_CLOAK_CLIENT_ID = environment.keycloak.clientId;
export const FULKRUM_WORKSPACE_URL = environment.Fulkrum_Workspace_URL;
export const ACTIVE_PROJECT = environment.ACTIVE_PROJECT;
export const SESSION_CHECK_TIMER = environment.SESSION_CHECK_TIMER;
export const IDLE_SCREEN_LOGOUT = environment.IDLE_SCREEN_LOGOUT;
export const IS_SAAS_ENABLED = environment.IS_SAAS_ENABLED;
export const CIAM_NAVBAR_ROLE_MIGRATION_FLAG = environment.CIAM_NAVBAR_ROLE_MIGRATION_FLAG;
export let REBAC_UM_ENTITLEMENT_FLAG = environment.REBAC_UM_ENTITLEMENT_FLAG;
export let MULTI_TENANCY_ENABLE = environment.MULTI_TENANCY_ENABLE;
export let LOGOUT_METHOD=environment.LOGOUT_METHOD;
if (environment.MULTI_TENANCY_ENABLE.toString() == "true") {
    var realm = location?.hostname.slice(0, location?.hostname.indexOf('.'));
}
else {
    var realm = environment.keycloak.realm;
}
export let KEY_CLOAK_REALM_NAME = realm;
