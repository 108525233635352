<p-dialog [(visible)]="displayifLoggedIn" [modal]="true" [draggable]="false" [resizable]="false" [closable]="false">
  <p-header>Multiple Login Detected!!!</p-header>
  <div class="md-12 p-2">
    Do you wish to logout from all previous browsers?
  </div>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="allOtherSessionLogout()" label="Yes"></button>
    <button type="button" pButton icon="pi pi-times" (click)="logout()" label="No" class="ui-button-secondary"></button>
  </p-footer>
</p-dialog>

<div>
  <div class="header1 custom_header custom-color custom-menu">
    <div *ngIf="sublogo">

    </div>
    <p-menubar>
      <ng-template pTemplate="start">

        <button class="mobiletoggle mthide custom-color" *ngIf="crossBtn" #btn type="button" pButton icon="pi pi-times"
          label=""></button>
        <button class="mobiletoggle mtshow hide-mobile custom-color" #btn type="button" pButton icon="icon-menu"
          label=""></button>

        <div>

          <img alt="" class="navbar-brand img_csss hide-mobile" src="{{this.redirect}}navbar/CrisilNewLogo.png" />

        </div>
        <button pButton type="button" label="Marketplace" (click)="routerLinkFunc('/marketplace')"
          style="background: transparent;border: none;box-shadow: none;" *ngIf="!this.alreadyLogin"></button>
      </ng-template>
      <ng-template pTemplate="end">
        <div class="p-d-flex">
          <form [formGroup]="NavbarForm" style="margin: auto;">
            <p-dropdown [(ngModel)]="selectedTenant" class="" [disabled]="this.dropDisable" id="tenantList"
              name="tenantList" filterPlaceHolder="Select Tenant" placeholder="{{tenantListList[0]?.label}}"
              [options]="tenantListList" (onChange)="sendTenantID()" [filter]="true" filterBy="label"
              optionLabel="label" optionValue="value" *ngIf="this.alreadyLogin">
              <ng-template let-item pTemplate="item">
                <div *ngIf="item.label?.length &lt;= 35;">
                  {{item.label}}
                </div>
                <div *ngIf="item.label?.length > 35;" pTooltip="{{item.label}}" tooltipPosition="bottom">
                  {{item.label.substring(0,35) + "..." }}
                </div>
              </ng-template>
            </p-dropdown>
          </form>

          <button pButton type="button" label="Login" (click)="loginRoute()"
            style="background: transparent;border: none;" *ngIf="!this.alreadyLogin"></button>

          <button (click)="getValue()" type="text" pButton label="" pTooltip="My Account {{ userFullName1 }}"
            tooltipPosition="bottom" (click)="op1.toggle($event)" class="header-button custom-signout custom-color"
            *ngIf="this.alreadyLogin" style="width: 40px;margin-left: 16px;">
            <p-avatar styleClass="p-mr-2" size="xsmall" shape="circle" label="{{ initials }}"
              class="header-avatar custom-color custom-avatar-circle"></p-avatar>
          </button>

          <p-overlayPanel styleClass="actSwitch" [style]="{width:'340px'}" #op1>
            <ng-template pTemplate>

              <div class="p-grid act-header custom-col" style="height: 96px;background: #F4F5F6;">
                <div class="p-col-fixed" style="width: 64px;height: 64px;margin-top: 8px;">

                  <img alt="" src="{{this.redirect}}navbar/userProfileLogo.png" width="64" />
                </div>
                <div class="p-col" style="margin-left:16px ;">
                  <h6 class="p-mb-0">{{ userFullName1 }}</h6>
                  <p class="p-mb-1" style="color: #666D82;">{{ userEmail }}</p>
                  <p style="font-size: 12px;font-weight: 400;color: #666D82;">
                    Last Login: {{this.lastLoggedDate}}</p>
                </div>
              </div>
              <div style="padding:12px ;padding-bottom: 0;">
                <span>
                  <div class="p-grid p-nogutter" style="align-items: center;">
                    <div class="p-col-fixed">
                      <i aria-hidden="true" style="font-size: 1rem; color: Black/Shade/50;" class="pi pi-user"></i>
                    </div>
                    <div class="p-col" style="margin-left: 10px;">
                      <label class="extraLargelabel p-regular pointer" (click)="redirectToProfile()">Profile</label>
                    </div>
                  </div>
                </span>
                <span class="custom-disabled-temp">
                  <div class="p-grid p-nogutter" style="align-items: center;">
                    <div class="p-col-fixed">
                      <i aria-hidden="true" style="font-size: 1rem; color: Black/Shade/50;" class="pi pi-sliders-v"></i>
                    </div>
                    <div class="p-col" style="margin-left: 10px;">
                      <label class="extraLargelabel p-regular custom-disabled-temp">Preferences</label>
                    </div>
                  </div>
                </span>
                <span *ngIf="setPwdFlag">
                  <div class="p-grid p-nogutter" style="align-items: center;">
                    <div class="p-col-fixed">
                      <i aria-hidden="true" style="font-size: 1rem; color: Black/Shade/50;" class="pi pi-unlock"></i>
                    </div>
                    <div class="p-col" style="margin-left: 10px;">
                      <label class="extraLargelabel p-regular pointer" (click)="onChangePassword()">Change
                        Password</label>
                    </div>
                  </div>
                </span>
                <span class="custom-disabled-temp">
                  <div class="p-grid p-nogutter" style="align-items: center;">
                    <div class="p-col-fixed">
                      <i aria-hidden="true" style="font-size: 1rem; color: Black/Shade/50;" class="pi pi-key"></i>
                    </div>
                    <div class="p-col" style="margin-left: 10px;">
                      <label class="extraLargelabel p-regular custom-disabled-temp">
                        Set prefered Multi Factor Authentication</label>
                    </div>
                  </div>
                </span>
                <span class="custom-disabled-temp">
                  <div class="p-grid p-nogutter" style="align-items: center;">
                    <div class="p-col-fixed">
                      <i aria-hidden="true" style="font-size: 1rem; color: Black/Shade/50;" class="pi pi-file"></i>
                    </div>
                    <div class="p-col" style="margin-left: 10px;">
                      <label class="extraLargelabel p-regular custom-disabled-temp">Logs</label>
                    </div>
                  </div>
                </span>
              </div>
              <p-divider style="width:fit-content"></p-divider>
              <div style="padding: 12px;padding-top: 0;">
                <span>
                  <div class="p-grid p-nogutter" style="align-items: center;">
                    <div class="p-col-fixed pointer">
                      <i aria-hidden="true" style="font-size: 1rem; color: Black/Shade/50;" class="pi pi-sign-out"></i>
                    </div>
                    <div class="p-col" style="margin-left: 10px;">
                      <label class="extraLargelabel p-regular pointer" (click)="logout()">Sign Out</label>
                    </div>
                  </div>
                </span>
              </div>
              <ul style="display: none;">
                <li class="pointer" (click)="redirectToProfile()">
                  <div class="p-grid p-nogutter">
                    <div class="p-col-fixed" style="width:52px">
                      <i aria-hidden="true" style="font-size: 1rem; color: Black/Shade/50;" class="pi pi-user"></i>
                    </div>
                    <div class="p-col">
                      <label class="extraLargelabel p-medium">Profile</label>
                    </div>
                  </div>

                </li>
                <li class="custom-disabled-temp">

                  <div class="p-grid p-nogutter">
                    <div class="p-col-fixed" style="width:52px">
                      <i aria-hidden="true" style="font-size: 1rem; color: Black/Shade/50;" class="pi pi-sliders-v"></i>
                    </div>
                    <div class="p-col">
                      <label class="extraLargelabel p-regular">Preferences</label>
                    </div>
                  </div>
                </li>
                <li *ngIf="setPwdFlag" class="pointer" (click)="onChangePassword()">
                  <div class="p-grid p-nogutter">
                    <div class="p-col-fixed" style="width:52px">
                      <i aria-hidden="true" style="font-size: 1rem; color: Black/Shade/50;" class="pi pi-unlock"></i>
                    </div>
                    <div class="p-col">
                      <label>Change Password</label>
                    </div>
                  </div>
                </li>
                <li class="custom-disabled-temp">

                  <div class="p-grid p-nogutter">
                    <div class="p-col-fixed" style="width:52px">
                      <i aria-hidden="true" style="font-size: 1rem; color: Black/Shade/50;" class="pi pi-key"></i>
                    </div>
                    <div class="p-col">
                      <label class="extraLargelabel p-regular">
                        Set prefered Multi Factor Authentication</label>
                    </div>
                  </div>
                </li>
                <li class="custom-disabled-temp">
                  <div class="p-grid p-nogutter">
                    <div class="p-col-fixed" style="width:52px">
                      <i aria-hidden="true" style="font-size: 1rem; color: Black/Shade/50;" class="pi pi-file"></i>
                    </div>
                    <div class="p-col">
                      <label class="extraLargelabel p-regular">Logs</label>
                    </div>
                  </div>
                </li>
                <p-divider style="width:fit-content"></p-divider>
                <li class="pointer" (click)="logout()">
                  <div class="p-grid p-nogutter">
                    <div class="p-col-fixed pointer" style="width:52px">
                      <i aria-hidden="true" style="font-size: 1rem; color: Black/Shade/50;" class="pi pi-sign-out"></i>
                    </div>
                    <div class="p-col">
                      <label class="extraLargelabel p-medium">Sign Out</label>
                    </div>
                  </div>
                </li>
              </ul>
            </ng-template>
          </p-overlayPanel>
          <!-- account switcher -->

        </div>
      </ng-template>
    </p-menubar>

  </div>
</div>

<p-dialog [(visible)]="display" [modal]="true" [draggable]="false" [resizable]="false" [closable]="false">
  <p-header>Timeout</p-header>
  <div class="md-12 p-2">
    Your session will expire in <span class="timer-text" style="color:#dc3545; font-weight: 900;">{{counter}}
    </span>seconds, Do you
    want to continue?
  </div>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="accept()" label="Ok"></button>
    <button type="button" pButton icon="pi pi-times" label="Logout" class="ui-button-secondary"
      (click)="reject()"></button>
  </p-footer>
</p-dialog>