import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NotificationComponent } from './notification.component';
import { NOTIFICATION_ROUTES } from './notification.route';

@NgModule({
    declarations: [NotificationComponent],
    imports: [
        RouterModule.forChild(NOTIFICATION_ROUTES)
    ]
})
export class NotificationModule {}
