import { environment } from 'src/environments/environment';
import { Component, OnInit, RendererFactory2, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { FDSService, MyronFDSService } from '@crisil/fds-lib';
import { NgxSpinnerService } from "ngx-spinner";
import { LoadingIndicatorService } from "./loading-indicator.service";
import { REDIRECT_URI, MULTI_TENANCY_ENABLE } from './app.constant';

@Component({
  selector: 'navbar-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  private renderer: Renderer2;
  loading = false;
  title = 'navbar';
  sessionId: string;
  inOninit: boolean;

  constructor(private keyloackservice: KeycloakService, private router: Router, private fdsService: FDSService,
    private spinner: NgxSpinnerService, rootRenderer: RendererFactory2, private loadingIndicatorService: LoadingIndicatorService,
    private myronFdsService: MyronFDSService
  ) {
    this.keyloackservice.getToken().then(function (token) {
      localStorage.setItem("token", token);
    });
    let multitenancy_enable = environment.MULTI_TENANCY_ENABLE + "";
    if (multitenancy_enable == "true") {
      this.myronFdsService.Init("light", "accounts");
      // this.fdsService.Init("light");
    }
    else {
      this.fdsService.Init("light");
      // this.myronFdsService.Init("light", "accounts");
    }



    localStorage.setItem("refreshToken", this.keyloackservice.getKeycloakInstance().refreshToken);
    localStorage.setItem("idToken", this.keyloackservice.getKeycloakInstance().idToken);
    localStorage.setItem("sessionId", this.keyloackservice.getKeycloakInstance().sessionId);

    this.loadingIndicatorService.onLoadingChanged.subscribe(
      (isLoading: boolean) => {
        this.loading = isLoading;
        this.sessionId = localStorage.getItem("sessionId");
        if (this.sessionId === "undefined") {
          if (this.loading) {
            this.spinner.show();
          } else {
            this.spinner.hide();
          }
        }

      }

    );

  }

  ngOnInit() {
    this.inOninit = true;
  }

}
