<div>
    <div>
        <img alt="" src="{{this.redirect}}navbar/regBack.svg" style="width: 100vw;
        height: 100vh;
        position: fixed;
        z-index: 99;
        left: 0;
        top: 0;
        background: #fff no-repeat center;
        background-size: contain;">
    </div>
    <div class="layout-content ">
        <div class=" " *ngIf="false">
            <div id="columns_1_id" name="columns_1_name" class="p-grid p-formgrid p-mb-5">
                <div class="p-col-8 p-sm-8 p-md-8 p-lg-8 p-xl-8 p-offset-undefined undefined "
                    style="background:#F4F8FF;">
                    <div class="  ">

                        <p-carousel id="columns_1_0_columns_1_0_carousel_1_1_id"
                            name="columns_1_0_columns_1_0_carousel_1_1_name" orientation="horizontal" label="Carousel"
                            [numVisible]="1" [numScroll]="1" [value]="product" [circular]="true"
                             [autoplayInterval]="8000">
                            <ng-template let-product pTemplate="item">
                                <div class="product-item">
                                    <div class="product-item-content ">
                                        <div class="" style="width: 480px;">
                                            <img alt="" src="{{product.image}}" width="480px" height="398px">
                                            <h4 class="description p-text-center">{{product.description}}</h4>
                                            <h5 class="detail p-text-center">{{product.detail}}</h5>
                                        </div>

                                    </div>
                                </div>
                            </ng-template>
                        </p-carousel>
                    </div>

                </div>
                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 p-p-6 p-offset-0 undefined "
                    style="background: #FFFFFF;height: 589px;">
                    <div>
                        <p-messages key="myKey1"
                            style="position: fixed;margin-top: -50px;margin-left: 230px;z-index:9999;"
                            *ngIf="this.messageFlag"></p-messages>

                        <p-messages key="myKey2" style="position: fixed;margin-top: -50px;z-index:9999;"
                            *ngIf="this.messageFlag1"></p-messages>
                    </div>
                    <div class="">
                        <div class="p-col-3 p-sm-3 p-md-3 p-lg-3 p-xl-3 p-offset-0 p-right "
                            style="margin-left: 83%;margin-top: -32px;">

                            <img alt="" src="{{this.redirect}}navbar/Crisillogo.svg" style="width: 79px;height:36px;">

                        </div>
                    </div>
                    <form [formGroup]="verifyEmailForm" autocomplete="off" novalidate>
                        <div class="" *ngIf="!this.switchScreen" style="margin-top: 30%;">
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined p-mb-5"
                                style="margin-bottom: -5%;">
                                <div class="  ">
                                    <h5 id="personalDetails_id" name="personalDetails_name">
                                        Welcome!
                                    </h5>
                                    <p style="color:#666D82;">Create your account</p>
                                </div>
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined">
                                <div class="p-fluid ">
                                    <span class="d-block"> <label
                                            [ngClass]="{'field-required': verifyEmailForm.get('emailId')?.errors?.required}">
                                            Email
                                            <span class='invalid-feedback-astrik'>*</span> </label>
                                    </span>
                                    <input type="email" id="emailId" name="emailId" formControlName="emailId" pInputText
                                        placeholder="Enter your email"
                                        pattern="(?=.{1,64}@)[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)*@[^-][A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$"
                                        maxlength="50">

                                    <div class="invalid-feedback p-error"
                                        *ngIf="verifyEmailForm.get('emailId')!.invalid && (verifyEmailForm.get('emailId')!.dirty || verifyEmailForm.get('emailId')!.touched)">
                                        <div *ngIf="verifyEmailForm.get('emailId')?.errors?.required">
                                            Email Id is required.
                                        </div>
                                        <div *ngIf="verifyEmailForm.get('emailId')?.errors?.pattern">
                                            Email ID input is not in Email ID format
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined">
                                <div class="p-fluid p-field">
                                    <div class="btnwrap">
                                        <button pButton id="createAcount_id" (click)="verifyEmail()"
                                            name="createAcount_name" class="p-button p-button-md p-d-block p-mt-3"
                                            type="button" label="Verify Email ID" [disabled]="!verifyEmailForm.valid">
                                        </button>
                                    </div>
                                </div>
                                <p style="color:#666D82;" class="p-text-center">Have an account?<a href="#"
                                        (click)="alreadayHaveAc()">Login</a></p>
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6 p-offset-0 undefined">
                            </div>
                        </div>
                    </form>
                    <form [formGroup]="verifyOTPForm" autocomplete="off" novalidate>
                        <div class="p-mt-6" *ngIf="this.switchScreen && !this.switchScreen1">
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined ">
                                <div class="  ">
                                    <h5 id="personalDetails_id" name="personalDetails_name">
                                        Let's verify your email <address></address>
                                    </h5>
                                    <p style="color:#666D82;">Enter the OTP we sent to your email address</p>
                                    <p><strong>{{this.maskedEmail}}</strong></p>
                                </div>
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined">
                                <div class="p-d-flex p-fluid p-jc-center">
                                    <!-- <otp [setting]="settings" (onValueChange)="onInputChange($event)"></otp> -->
                                </div>
                            </div>

                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined">
                                <div class="p-fluid p-field ">
                                    <div class="btnwrap">
                                        <button pButton id="createAcount_id" (click)="verifyOTP()"
                                            name="createAcount_name" class="p-button p-button-md p-d-block p-mt-3"
                                            type="button" label="Next" [disabled]="!this.btnFlag">
                                        </button>
                                    </div>
                                </div>
                                <p style="color:#505870;height:18px;margin-left: 45%;">{{display}}</p>
                                <div class="resend-label-color">
                                    <button pButton type="button" style="text-align: center;margin-left: 35%;"
                                        (click)="verifyEmail()" label="Resend OTP" class="p-button-info"
                                        [disabled]="!this.resendFlag"></button>
                                </div>
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6 p-offset-0 undefined">
                            </div>
                        </div>
                    </form>
                    <form [formGroup]="RegistrationForm" autocomplete="off" novalidate>
                        <div class="" *ngIf="this.switchScreen1 && this.switchScreen">
                            <div id="columns_1_1_columns_1_id" name="columns_1_1_columns_1_name" class="p-grid   ">
                                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined undefined ">
                                </div>
                                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined ">
                                    <div class="  ">
                                        <h5 id="personalDetails_id" name="personalDetails_name" class="heading">
                                            Personal Details
                                        </h5>
                                    </div>
                                </div>
                                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined">
                                    <div class="p-fluid">
                                        <span class="d-block"> <label
                                                [ngClass]="{'field-required': RegistrationForm.get('firstName')?.errors?.required}"
                                                required>
                                                First name
                                                <span class='invalid-feedback-astrik'>*</span> </label>
                                        </span>
                                        <input type="text" id="firstName_id" name="firstName_name"
                                            formControlName="firstName" pInputText placeholder="Enter your First name "
                                            pattern="^([a-zA-Z]+\s)*[a-zA-Z]+$">
                                        <div class="invalid-feedback p-error"
                                            *ngIf="RegistrationForm.get('firstName')!.invalid && (RegistrationForm.get('firstName')!.dirty || RegistrationForm.get('firstName')!.touched)">
                                            <div *ngIf="RegistrationForm.get('firstName')?.errors?.required">
                                                <span class="p-error">First name is required.</span>
                                            </div>
                                            <div *ngIf="RegistrationForm.get('firstName').errors.pattern">
                                                <label class="alert-icon alert-circle-icon"></label>
                                                <span class="p-error">First name should not have Special Character and
                                                    number. </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined">
                                    <div class="p-fluid ">
                                        <span class="d-block"> <label
                                                [ngClass]="{'field-required': RegistrationForm.get('lastName')?.errors?.required}">
                                                Last name
                                                <span class='invalid-feedback-astrik'>*</span> </label>
                                        </span>
                                        <input type="text" id="lastName_id" name="lastName_name"
                                            formControlName="lastName" pInputText placeholder="Enter your Last name"
                                            pattern="^([a-zA-Z]+\s)*[a-zA-Z]+$">
                                        <div class="invalid-feedback"
                                            *ngIf="RegistrationForm.get('lastName')!.invalid && (RegistrationForm.get('lastName')!.dirty || RegistrationForm.get('lastName')!.touched)">
                                            <div *ngIf="RegistrationForm.get('lastName')?.errors?.required">
                                                <span class="p-error"> Last name is required.</span>
                                            </div>
                                            <div *ngIf="RegistrationForm.get('lastName').errors.pattern">
                                                <label class="alert-icon alert-circle-icon"></label>
                                                <span class="p-error">Last name should not have Special Character and
                                                    number. </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined">
                                    <div class=" ">
                                        <h5 id="accountDetails_id" name="accountDetails_name" class="heading">
                                            Account details
                                        </h5>
                                    </div>
                                </div>
                                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined">
                                    <div class="p-fluid ">
                                        <span class="d-block"> <label>
                                                Account type
                                            </label>
                                        </span>
                                        <div class="p-field p-fluid">
                                            <p-selectButton id="accountType_id" name="accountType_name"
                                                [options]="accountTypeList" [(ngModel)]="accountTypeselectedTypes"
                                                formControlName="accountType" styleClass=default
                                                (onChange)="acconttype()" class="p-selectbutton">

                                            </p-selectButton>
                                            <div *ngIf="RegistrationForm.get('accountType')?.errors?.required">
                                                <span class="p-error"> Account type is required.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined">
                                    <div class="p-fluid">
                                        <span class="d-block"> <label
                                                [ngClass]="{'field-required': RegistrationForm.get('organizationName')?.errors?.required}">
                                                Organization name
                                                <span class='invalid-feedback-astrik'>*</span> </label>
                                        </span>
                                        <input type="text" id="organizationName_id" name="organizationName_name"
                                            formControlName="organizationName" pattern="^([a-z0-9]+-)*[a-z0-9]+$"
                                            pInputText placeholder="Enter Organization name" maxlength="64"
                                            minlength="3" (keyup)="domainname();">

                                        <div class="invalid-feedback"
                                            *ngIf="RegistrationForm.get('organizationName')!.invalid && (RegistrationForm.get('organizationName')!.dirty || RegistrationForm.get('organizationName')!.touched)">
                                            <div *ngIf="RegistrationForm.get('organizationName')?.errors?.required">
                                                <span class="p-error"> Organization name is required.</span>
                                            </div>
                                            <div
                                                *ngIf="RegistrationForm.get('organizationName')?.errors.minlength!=3 && RegistrationForm.get('organizationName')?.errors.minlength!=null">
                                                <label class="alert-icon alert-circle-icon"></label>
                                                <span class="p-error">Minimum 3 and maximum 64 characters are
                                                    allowed.</span>
                                            </div>
                                            <div
                                                *ngIf="RegistrationForm.get('organizationName').errors.pattern && this.RegistrationForm.value.organizationName!=null">
                                                <label class="alert-icon alert-circle-icon"></label>
                                                <span class="p-error">Use only lowercase alphanumeric characters &
                                                    hyphen, don’t start & end with a hyphen</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="p-col-6 p-sm-6 p-md-6 p-lg-6 p-xl-6 p-offset-0 undefined">
                                    <div class="p-fluid ">
                                        <span class="d-block"> <label
                                                [ngClass]="{'field-required': RegistrationForm.get('domainName')?.errors?.required}">
                                                Alias name
                                                <span class='invalid-feedback-astrik'>*</span> </label>
                                        </span>
                                        <input type="text" id="domainName_id" name="domainName_name"
                                            [(ngModel)]="organizationName" formControlName="domainName" pInputText
                                            placeholder="Alias name" (keyup)="this.editedFlag=true;" pattern="^\S*$">

                                        <div class="invalid-feedback"
                                            *ngIf="RegistrationForm.get('domainName')!.invalid && (RegistrationForm.get('domainName')!.dirty || RegistrationForm.get('domainName')!.touched)">
                                            <div *ngIf="RegistrationForm.get('domainName')?.errors?.required">
                                                <span class="p-error"> Alias name is required.</span>
                                            </div>
                                            <div *ngIf="RegistrationForm.get('domainName')?.errors?.pattern">
                                                <span class="p-error"> Space is not allowed</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-6 p-sm-6 p-md-6 p-lg-6 p-xl-6 p-offset-0 undefined">
                                    <div class="p-fluid ">
                                        <span class="d-block"> <label
                                                [ngClass]="{'field-required': RegistrationForm.get('domainName1')?.errors?.required}">

                                            </label>
                                        </span>
                                        <input type="text" id="domainName_id" pTooltip="{{this.domain}}"
                                            tooltipPosition="top" name="domainName_name" [(ngModel)]="domain"
                                            formControlName="domainName1" pInputText placeholder="Domain name" disabled>


                                    </div>
                                </div>
                                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined">
                                    <div class="p-fluid p-field ">
                                        <div class="btnwrap">
                                            <button pButton id="createAcount_id" (click)="loginRoute()"
                                                name="createAcount_name" class="p-button p-button-md p-d-block p-mt-3"
                                                type="button" [disabled]="!RegistrationForm.valid"
                                                label="Create account">
                                            </button>
                                        </div>
                                    </div>

                                </div>
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6 p-offset-0 undefined">
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="" style="right: 30px;
            top: 30px;
            /* margin-top: 0; */
            position: fixed;
            z-index: 999;">
            <img alt="" src="{{this.redirect}}navbar/Crisillogo.svg" style="width: 80px;height:40px;"
                (click)="routerLinkFunc('/marketplace')">
        </div>

        <div class="p-grid p-ai-center">
            <div class="p-col">
                <div class="box"></div>
            </div>
            <div class="p-col-fixed" style="width: 500px;
            margin-top: 20px;
            /* margin-bottom: 120px; */
            z-index: 99;">
                <div class="box">
                    <p-card>
                        <div style="padding:20px ;">
                            <div>
                                <p-messages key="myKey1" style="position: fixed;top: 0;
                            right: 140px;" *ngIf="this.messageFlag"></p-messages>

                                <p-messages key="myKey2" style="position: fixed;top: 0;
                            right: 140px;width:350px" *ngIf="this.messageFlag1"></p-messages>
                            </div>
                            <div class="">

                            </div>
                            <form [formGroup]="verifyEmailForm" autocomplete="off" novalidate>
                                <div class="" *ngIf="!this.switchScreen">
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined p-mb-5"
                                        style="margin-bottom: -5%;">
                                        <div class="  ">
                                            <span id="personalDetails_id" name="personalDetails_name"
                                                style="font-size: 20px;font-weight: 500;">
                                                Create your account
                                            </span>

                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined">
                                        <div class="p-fluid ">
                                            <span class="d-block"> <label
                                                    [ngClass]="{'field-required': verifyEmailForm.get('emailId')?.errors?.required}">
                                                    Email
                                                    <span class='invalid-feedback-astrik'>*</span> </label>
                                            </span>
                                            <input type="email" id="emailId" name="emailId" formControlName="emailId"
                                                pInputText placeholder="Enter your email"
                                                pattern="(?=.{1,64}@)[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)*@[^-][A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$"
                                                maxlength="50">

                                            <div class="invalid-feedback p-error"
                                                *ngIf="verifyEmailForm.get('emailId')!.invalid && (verifyEmailForm.get('emailId')!.dirty || verifyEmailForm.get('emailId')!.touched)">
                                                <div *ngIf="verifyEmailForm.get('emailId')?.errors?.required">
                                                    Email Id is required.
                                                </div>
                                                <div *ngIf="verifyEmailForm.get('emailId')?.errors?.pattern">
                                                    Email ID input is not in Email ID format
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined">
                                        <div class="p-fluid p-field">
                                            <div class="btnwrap">
                                                <button pButton id="createAcount_id" (click)="verifyEmail()"
                                                    name="createAcount_name" class="p-button p-button-md p-d-block"
                                                    type="button" label="Verify Email ID"
                                                    [disabled]="!verifyEmailForm.valid">
                                                </button>
                                            </div>
                                        </div>
                                        <p style="color:#666D82;" class="p-text-center p-mt-6">Have an account? <a
                                                href="#" (click)="alreadayHaveAc()">Login</a></p>
                                    </div>
                                    <div class="p-col-12 p-text-right" style="font-size: 12px;">
                                        <span>Step 1/3</span>
                                    </div>
                                </div>
                            </form>
                            <form [formGroup]="verifyOTPForm" autocomplete="off" novalidate>
                                <div class="p-mt-4" *ngIf="this.switchScreen && !this.switchScreen1">
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined ">
                                        <div class="  ">
                                            <h5 id="personalDetails_id" name="personalDetails_name">
                                                Let's verify your email <address></address>
                                            </h5>
                                            <p style="color:#666D82;">Enter the OTP we sent to your email address</p>
                                            <p><strong>{{this.maskedEmail}}</strong></p>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined">
                                        <div class="p-d-flex p-fluid p-jc-center">
                                            <ng-otp-input #ngOtpInput (onInputChange)="onInputChange($event)"
                                                [config]="{length:6}"></ng-otp-input>
                                            <!-- <otp *ngIf="false" [setting]="settings"
                                                (onValueChange)="onInputChange($event)"></otp> -->
                                        </div>
                                    </div>

                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined">
                                        <div class="p-fluid p-field ">
                                            <div class="btnwrap">
                                                <button pButton id="createAcount_id" (click)="verifyOTP()"
                                                    name="createAcount_name"
                                                    class="p-button p-button-md p-d-block p-mt-3" type="button"
                                                    label="Next" [disabled]="!this.btnFlag">
                                                </button>
                                            </div>
                                        </div>
                                        <p style="color:#505870;height:18px;margin-left: 45%;">{{display}}</p>
                                        <div class="resend-label-color">
                                            <button pButton type="button" style="text-align: center;margin-left: 35%;"
                                                (click)="verifyEmail();yourMethod();" label="Resend OTP"
                                                class="p-button-info" [disabled]="!this.resendFlag"></button>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-text-right" style="font-size: 12px;">
                                        <span>Step 2/3</span>
                                    </div>
                                </div>
                            </form>
                            <form [formGroup]="RegistrationForm" autocomplete="off" novalidate>
                                <div class="" *ngIf="this.switchScreen1 && this.switchScreen">
                                    <div id="columns_1_1_columns_1_id" name="columns_1_1_columns_1_name"
                                        class="p-grid   ">

                                        <div
                                            class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined p-pb-0">
                                            <div class="  ">
                                                <h5 id="personalDetails_id" name="personalDetails_name" class="heading">
                                                    Personal Details
                                                </h5>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined">
                                            <div class="p-fluid">
                                                <span class="d-block"> <label
                                                        [ngClass]="{'field-required': RegistrationForm.get('firstName')?.errors?.required}"
                                                        required>
                                                        First name
                                                        <span class='invalid-feedback-astrik'>*</span> </label>
                                                </span>
                                                <input type="text" id="firstName_id" name="firstName_name"
                                                    formControlName="firstName" pInputText
                                                    placeholder="Enter your First name "
                                                    pattern="^([a-zA-Z]+\s)*[a-zA-Z]+$">
                                                <div class="invalid-feedback p-error"
                                                    *ngIf="RegistrationForm.get('firstName')!.invalid && (RegistrationForm.get('firstName')!.dirty || RegistrationForm.get('firstName')!.touched)">
                                                    <div *ngIf="RegistrationForm.get('firstName')?.errors?.required">
                                                        <span class="p-error">First name is required.</span>
                                                    </div>
                                                    <div *ngIf="RegistrationForm.get('firstName').errors.pattern">
                                                        <label class="alert-icon alert-circle-icon"></label>
                                                        <span class="p-error">First name should not have Special
                                                            Character and number. </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined">
                                            <div class="p-fluid ">
                                                <span class="d-block"> <label
                                                        [ngClass]="{'field-required': RegistrationForm.get('lastName')?.errors?.required}">
                                                        Last name
                                                        <span class='invalid-feedback-astrik'>*</span> </label>
                                                </span>
                                                <input type="text" id="lastName_id" name="lastName_name"
                                                    formControlName="lastName" pInputText
                                                    placeholder="Enter your Last name"
                                                    pattern="^([a-zA-Z]+\s)*[a-zA-Z]+$">
                                                <div class="invalid-feedback"
                                                    *ngIf="RegistrationForm.get('lastName')!.invalid && (RegistrationForm.get('lastName')!.dirty || RegistrationForm.get('lastName')!.touched)">
                                                    <div *ngIf="RegistrationForm.get('lastName')?.errors?.required">
                                                        <span class="p-error"> Last name is required.</span>
                                                    </div>
                                                    <div *ngIf="RegistrationForm.get('lastName').errors.pattern">
                                                        <label class="alert-icon alert-circle-icon"></label>
                                                        <span class="p-error">Last name should not have Special
                                                            Character and number. </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined p-pb-0">
                                            <div class=" ">
                                                <h5 id="accountDetails_id" name="accountDetails_name"
                                                    class="heading p-mb-0">
                                                    Account details
                                                </h5>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-6 p-offset-0 undefined p-pb-0">
                                            <div class="p-fluid ">
                                                <span class="d-block"> <label>
                                                        Account type
                                                    </label>
                                                </span>
                                                <div class="p-field p-fluid">
                                                    <p-selectButton id="accountType_id" name="accountType_name"
                                                        [options]="accountTypeList"
                                                        [(ngModel)]="accountTypeselectedTypes"
                                                        formControlName="accountType" styleClass=default
                                                        (onChange)="acconttype()" class="p-selectbutton">

                                                    </p-selectButton>
                                                    <div *ngIf="RegistrationForm.get('accountType')?.errors?.required">
                                                        <span class="p-error"> Account type is required.</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined">
                                            <div class="p-fluid">
                                                <span class="d-block"> <label
                                                        [ngClass]="{'field-required': RegistrationForm.get('organizationName')?.errors?.required}">
                                                        Organization name
                                                        <span class='invalid-feedback-astrik'>*</span> </label>
                                                </span>
                                                <input type="text" id="organizationName_id" name="organizationName_name"
                                                    formControlName="organizationName"
                                                    pattern="^([a-z0-9]+-)*[a-z0-9]+$" pInputText
                                                    placeholder="Enter Organization name" maxlength="64" minlength="3"
                                                    (keyup)="domainname();">

                                                <div class="invalid-feedback"
                                                    *ngIf="RegistrationForm.get('organizationName')!.invalid && (RegistrationForm.get('organizationName')!.dirty || RegistrationForm.get('organizationName')!.touched)">
                                                    <div
                                                        *ngIf="RegistrationForm.get('organizationName')?.errors?.required">
                                                        <span class="p-error"> Organization name is required.</span>
                                                    </div>
                                                    <div
                                                        *ngIf="RegistrationForm.get('organizationName')?.errors.minlength!=3 && RegistrationForm.get('organizationName')?.errors.minlength!=null">
                                                        <label class="alert-icon alert-circle-icon"></label>
                                                        <span class="p-error">Minimum 3 and maximum 64 characters are
                                                            allowed.</span>
                                                    </div>
                                                    <div
                                                        *ngIf="RegistrationForm.get('organizationName').errors.pattern && this.RegistrationForm.value.organizationName!=null">
                                                        <label class="alert-icon alert-circle-icon"></label>
                                                        <span class="p-error">Use only lowercase alphanumeric characters
                                                            & hyphen, don’t start & end with a hyphen</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="p-col-6 p-sm-6 p-md-6 p-lg-6 p-xl-6 p-offset-0 undefined">
                                            <div class="p-fluid ">
                                                <span class="d-block"> <label
                                                        [ngClass]="{'field-required': RegistrationForm.get('domainName')?.errors?.required}">
                                                        Alias name
                                                        <span class='invalid-feedback-astrik'>*</span> </label>
                                                </span>
                                                <input type="text" id="domainName_id" name="domainName_name"
                                                    [(ngModel)]="organizationName" formControlName="domainName"
                                                    pInputText placeholder="Alias name" (keyup)="this.editedFlag=true;"
                                                    pattern="^\S*$">

                                                <div class="invalid-feedback"
                                                    *ngIf="RegistrationForm.get('domainName')!.invalid && (RegistrationForm.get('domainName')!.dirty || RegistrationForm.get('domainName')!.touched)">
                                                    <div *ngIf="RegistrationForm.get('domainName')?.errors?.required">
                                                        <span class="p-error"> Alias name is required.</span>
                                                    </div>
                                                    <div *ngIf="RegistrationForm.get('domainName')?.errors?.pattern">
                                                        <span class="p-error"> Space is not allowed</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-col-6 p-sm-6 p-md-6 p-lg-6 p-xl-6 p-offset-0 undefined"
                                            style="margin-top: 3px;">
                                            <div class="p-fluid ">
                                                <span class="d-block"> <label
                                                        [ngClass]="{'field-required': RegistrationForm.get('domainName1')?.errors?.required}">

                                                    </label>
                                                </span>
                                                <input type="text" style="height: 32px;" id="domainName_id"
                                                    pTooltip="{{this.domain}}" tooltipPosition="top"
                                                    name="domainName_name" [(ngModel)]="domain"
                                                    formControlName="domainName1" pInputText placeholder="Domain name"
                                                    disabled>


                                            </div>
                                        </div>
                                        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined">
                                            <div class="p-fluid p-field ">
                                                <div class="btnwrap">
                                                    <button pButton id="createAcount_id" (click)="loginRoute()"
                                                        name="createAcount_name"
                                                        class="p-button p-button-md p-d-block p-mt-3" type="button"
                                                        [disabled]="!RegistrationForm.valid" label="Create account">
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="p-col-12 p-text-right" style="font-size: 12px;">
                                            <span>Step 3/3</span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </p-card>
                </div>
            </div>
            <div class="p-col">
                <div class="box"></div>
            </div>
        </div>
    </div>
</div>