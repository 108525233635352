import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { REDIRECT_URI } from './app.constant';

@Injectable({ providedIn: 'root' })
export class PagesService {
  public alreadyLogin = false;
  private userId: any;
  public redirect: any
  constructor(private http: HttpClient) {
    this.redirect = location.origin + "/";
    console.log(this.redirect);
    // this.redirect = 'http://localhost:8080/';
    // this.redirect = 'https://ciam-dev.crisil.local/';
    // console.log(this.redirect);
  }
  getAppList(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(this.redirect + `ciamservice/ciam/v1/saas-regi/open/get-saas-host-app`, {
      headers: headersList,
    });
  }
  getApprovalsListing(formValues: any): Observable<any> {
    return this.http.post(this.redirect + `ciamservice/ciam/v1/workflow/get-workflow-transaction`, formValues
    );
  }
  getPlanList(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(this.redirect + `ciamservice/ciam/v1/saas-regi/open/getMarketplaceplan`, {
      headers: headersList,
    });
  }
  getPlanListByAppId(formValues: any): Observable<any> {
    return this.http.post(this.redirect + `ciamservice/ciam/v1/saas-regi/open/getMarketplaceplan`, formValues);
  }
  getAllPlanAttributes(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(this.redirect + `ciamservice/ciam/v1/saas-regi/open/getPlanAttributes`, {
      headers: headersList,
    });
  }
  verifyEmail(formValues: any): Observable<any> {
    return this.http.post(this.redirect + `ciamservice/ciam/v1/saas-regi/open/send-otp-email`,
      formValues
    );
  }
  register(formValues: any): Observable<any> {
    return this.http.post(this.redirect + `ciamservice/ciam/v1/saas-regi/open/register-account`,
      formValues
    );
  }
  verifyOTP(formValues: any): Observable<any> {
    return this.http.post(this.redirect + `ciamservice/ciam/v1/saas-regi/open/verify-otp`,
      formValues
    );
  }
  applicationLogo(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(
      this.redirect + `ciamservice/ciam/v1/lookups/files/${data}`, {
      headers: headersList,
    });
  }
}