

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { PagesService } from '../pages.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'jhi-marketplace',
  templateUrl: './Marketplace.component.html',
  styleUrls: ['./Marketplace.component.scss'],
})
export class MarketplaceComponent implements OnInit {
  hasCode = false;
  heightTable: any = 180;
  viewMore: boolean = true;
  viewToggleName: string;
  viewToggleIcon: string;
  MarketplaceForm = this.fb.group({
    columns_1_0_columns_1_2_ccprimeinputtext_1: [null],
  }
  );
  defaultValue: boolean = false;
  pageloadvalues: any;
  clipPropertycolumns_1_0_columns_1_0_ccparagraph_1: string = '';
  clipPropertycolumns_1_0_columns_1_1_ccparagraph_1: string = '';
  clipPropertycolumns_1_1_ccprimeheading_1: string = '';
  clipPropertycolumns_1_3_ccprimeheading_1: string = '';
  tableList: any;
  colors: any = ['p-avatar-color1', 'p-avatar-color2', 'p-avatar-color3', 'p-avatar-color4', 'p-avatar-color5', 'p-avatar-color6', 'p-avatar-color7', 'p-avatar-color8'];
  redirect: string;


  constructor(private router: Router, protected activatedRoute: ActivatedRoute, private fb: UntypedFormBuilder, private pagesService: PagesService) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.MarketplaceForm.patchValue(JSON.parse(data));
    }

  }
  ngOnInit(): void {
    const saasFlag = environment.IS_SAAS_ENABLED + "";
    if (this.pagesService.alreadyLogin) {
      this.router.navigate(["/ciam"]);

    }
    else {
      if (saasFlag.toString() == "true") {
        this.router.navigate(["/marketplace"]);
      } else {
        this.router.navigate(["/login"]);
      }
    }
    this.redirect = this.pagesService.redirect;
    this.gettableList();
    this.onClipConvertingcolumns_1_0_columns_1_0_ccparagraph_1()
    this.onClipConvertingcolumns_1_0_columns_1_1_ccparagraph_1()
    this.onClipConvertingcolumns_1_1_ccprimeheading_1()
    this.onClipConvertingcolumns_1_3_ccprimeheading_1()
  }

  getColor(i: any) {
    let count = 0;
    if (i < this.colors?.length) {
      return this.colors[i]
    }

    if (i >= this.colors?.length) {
      for (let j = 0; j <= i; j++) {
        if (j % this.colors?.length == 0 && j != 0) {
          count++;
        }
      }
      return this.colors[i - (this.colors?.length * count)]
    }

  }

  gettableList(): void {
    this.pagesService.getAppList().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData?.length > 0) {
        this.tableList = responseData;
      }
    });
  }
  routeToDetail(rowData: any) {
    const navigationExtras: NavigationExtras = {
      state: {
        data: rowData
      },
    };
    this.router.navigate(['/appdetail'], navigationExtras);

  }

  viewToggle() {
    this.viewMore = !this.viewMore;
    if (this.viewMore) {
      document.getElementById("cardHeight")!.classList.remove("cardCollapse");
      document.getElementById("cardHeight")!.classList.add("cardExpand");
      this.viewToggleName = "View Less";
      this.viewToggleIcon = "pi pi-chevron-up";
    }
    else {
      document.getElementById("cardHeight")!.classList.add("cardCollapse");
      document.getElementById("cardHeight")!.classList.remove("cardExpand");
      this.viewToggleName = "View More";
      this.viewToggleIcon = "icon-arrowDown";
    }
  }

  onClipConvertingcolumns_1_0_columns_1_0_ccparagraph_1() {
    let title = 'Welcome to CRISIL’S Marketplace!, Discover &amp; deploy the cloud software you want.'
    this.clipPropertycolumns_1_0_columns_1_0_ccparagraph_1 = title.substring(0, +1) + '...';
  }

  onClipConvertingcolumns_1_0_columns_1_1_ccparagraph_1() {
    let title = 'Marketplace lets you quickly deploy software on Cloud'
    this.clipPropertycolumns_1_0_columns_1_1_ccparagraph_1 = title.substring(0, +1) + '...';
  }

  onClipConvertingcolumns_1_1_ccprimeheading_1() {
    let title = 'Featured Apps'
    this.clipPropertycolumns_1_1_ccprimeheading_1 = title.substring(0, +1) + '...';
  }

  onClipConvertingcolumns_1_3_ccprimeheading_1() {
    let title = 'Trending Apps'
    this.clipPropertycolumns_1_3_ccprimeheading_1 = title.substring(0, +1) + '...';
  }
}

