export const environment = {
  production: true,

  envName: 'production',
  keycloak: {
    issuer: 'NAVBAR_UI_KEYCLOAK_ISSUER_VALUE', //Cubix Originam
    realm: 'NAVBAR_UI_KEYCLOAK_REALM_VALUE',  //Cubix
    clientId: 'NAVBAR_UI_KEYCLOAK_CLIENTID_VALUE',
  },
  //USER_MANAGEMENT_SERVER_API_URL : 'USER_MANAGEMENT_API_URL_VALUE',
  REDIRECT_URI: 'NAVBAR_UI_REDIRECT_URI_VALUE',
  IMAGE_PATH: 'NAVBAR_UI_IMAGE_PATH_VALUE',
  profile: 'NAVBAR_UI_PROFILE_VALUE',
  Fulkrum_Workspace_URL: 'NAVBAR_UI_FULKRUM_WORKSPACE_URL_VALUE',
  ACTIVE_PROJECT: 'NAVBAR_UI_ACTIVE_PROJECT_VALUE',
  IS_SAAS_ENABLED: 'IS_SAAS_ENABLED',
  CIAM_NAVBAR_ROLE_MIGRATION_FLAG: 'CIAM_NAVBAR_ROLE_MIGRATION_FLAG',
  REBAC_UM_ENTITLEMENT_FLAG: 'REBAC_UM_ENTITLEMENT_FLAG_VALUE',
  MULTI_TENANCY_ENABLE: 'MULTI_TENANCY_ENABLE_VALUE',
  SESSION_CHECK_TIMER: 10000, //10 secs
  IDLE_SCREEN_LOGOUT: 840,//14 mins
  LOGOUT_METHOD:'LOGOUT_METHOD_VALUE'
};