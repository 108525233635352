import { CiamLoginComponent } from 'src/app/ciamlogin/ciamlogin.component';
import { KEY_CLOAK_CLIENT_ID, KEYCLOAK_API_URL, KEY_CLOAK_REALM_NAME,MULTI_TENANCY_ENABLE } from './../app/app.constant';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';

export function initializer(keycloak: KeycloakService): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise<void>(async (resolve, reject) => {//NOSONAR
      try {
        var multiTenancyFlag = environment.MULTI_TENANCY_ENABLE + "";
        if (multiTenancyFlag == "true") {
          CiamLoginComponent.realm = location?.hostname.slice(0, location?.hostname.indexOf('.'));
        }
        else {
          CiamLoginComponent.realm = KEY_CLOAK_REALM_NAME;
        }
        await keycloak.init({
          config: {
            url: KEYCLOAK_API_URL,
            realm: CiamLoginComponent.realm,
            clientId: KEY_CLOAK_CLIENT_ID
          },
          loadUserProfileAtStartUp: false,
          initOptions: {
            onLoad: 'check-sso',
            checkLoginIframe: false,
          },
          enableBearerInterceptor: true,

          bearerExcludedUrls: [],
          bearerPrefix: 'Bearer'
        });
        keycloak.keycloakEvents$.subscribe({
          next: (e) => {
            console.log("token is expired , trying to refresh");
            if (e.type == KeycloakEventType.OnTokenExpired) {
              keycloak.updateToken(30).then((refreshed) => {
                if (refreshed) {
                  keycloak.getToken().then(tokenRes => {
                    localStorage.setItem("refreshToken", keycloak.getKeycloakInstance().refreshToken);
                    localStorage.setItem("idToken", keycloak.getKeycloakInstance().idToken);
                    localStorage.setItem("sessionId", keycloak.getKeycloakInstance().sessionId);
                    localStorage.setItem("token", keycloak.getKeycloakInstance().token);
                  })
                }
                else {
                  console.log("not refreshed");
                }
              }).catch(() => {
                console.log("failed to refresh");
              })
              console.log("token refreshed through event in app init");
            }
          }
        });
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };
}