import { CiamRouteComponent } from './ciamroute/ciamroute.component';
import { CiamLoginComponent } from './ciamlogin/ciamlogin.component';
import { MarketplaceComponent } from './Marketplace/Marketplace.component';
import { ApplicationDetailComponent } from './ApplicationDetail/ApplicationDetail.component';
import { RegistrationComponent } from './registration/registration.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { PrimaryNavComponent } from './primary-nav/primary-nav.component';
import { initializer } from 'src/utils/app-init';
import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import { ErrorComponent } from './error/error.component';
import { HomedashboardComponent } from './homedashboard/homedashboard.component';
import { FooterComponent } from './layout/footer.component';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { PanelModule } from 'primeng/panel';
import { FusionChartsModule } from "angular-fusioncharts";
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { BadgeModule } from 'primeng/badge';
// import { AngularOtpLibModule } from 'angular-otp-box';
import scrollline2d from "fusioncharts/viz/scrollline2d";
import pie2d from "fusioncharts/viz/pie2d";
import { CubixsHttpService } from './cubix-http/homedashboard-service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import msstackedcolumn2d from "fusioncharts/viz/msstackedcolumn2d";
import { HomedashboardService } from './homedashboard/homedashboard.service';
import { DialogModule } from 'primeng/dialog';
import { TabViewModule } from 'primeng/tabview';
import { NotificationModule } from '././notification/notification.module';
import { MenubarModule } from 'primeng/menubar';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { AvatarModule } from 'primeng/avatar';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { AsseturlpipePipe } from './asseturlpipe.pipe';
import { BnNgIdleService } from 'bn-ng-idle';
import { FdsModule } from '@crisil/fds-lib';
import { RegistrationService } from './registration/registration.service';
import { CarouselModule } from 'primeng/carousel';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { NgxSpinnerModule } from "ngx-spinner";
import { AuthInterceptor } from './blocks/interceptor/auth.interceptor';
import { LoadingIndicatorService } from './loading-indicator.service';
import { NgOtpInputModule } from 'ng-otp-input';
// Load FusionCharts
import * as FusionCharts from 'fusioncharts';
// Load Charts module
import * as Charts from 'fusioncharts/fusioncharts.charts';
// Load fusion theme
import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion'
FusionChartsModule.fcRoot(FusionCharts, Charts, FusionTheme);


@NgModule({
  declarations: [
    AppComponent,
    EmptyRouteComponent,
    PrimaryNavComponent,
    ErrorComponent,
    HomedashboardComponent,
    FooterComponent,
    AsseturlpipePipe,
    RegistrationComponent,
    MarketplaceComponent,
    ApplicationDetailComponent,
    CiamLoginComponent,
    CiamRouteComponent
  ],
  imports: [
    ButtonModule,
    FdsModule,
    InputTextModule,
    MenubarModule,
    NotificationModule,
    TabViewModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    KeycloakAngularModule,
    CardModule,
    ChartModule,
    PanelModule,
    FusionChartsModule,
    DialogModule,
    AvatarModule,
    AvatarGroupModule,
    TooltipModule,
    OverlayPanelModule,
    DividerModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    CarouselModule,
    SelectButtonModule,
    BreadcrumbModule,
    TableModule,
    PaginatorModule,
    TieredMenuModule,
    // AngularOtpLibModule,
    MessagesModule,
    MessageModule,
    NgxSpinnerModule,
    NgOtpInputModule,
    BadgeModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      deps: [KeycloakService, CubixsHttpService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
      deps: [LoadingIndicatorService]
    },
    HomedashboardService,
    BnNgIdleService,
    RegistrationService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  exports: [MessageModule, MessagesModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
